import { PropsWithChildren, useCallback, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import userStorage from "../../../utils/userStorage";

const AuthGuardLayout = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();

  const checkProfile = useCallback(() => {
    const userProfileResponse = userStorage.get();

    if (userProfileResponse === null) {
      navigate("/");
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (location.pathname === "/" || location.pathname.includes("/oauth"))
      return;
    checkProfile();
  }, [checkProfile, location.pathname]);

  return <>{children}</>;
};

export default AuthGuardLayout;
