import { useRef } from 'react';
import styled from "@emotion/styled";
import { Image } from 'antd';

const UserImageCarousel = ({ images }: { images: string[] }) => {
  const carouselRef = useRef<HTMLDivElement>(null);

  const containerWidth = images.length > 3 ? `calc(3 * (150px + 2px))` : `calc(${images.length} * (150px + 10px))`;


  return (
    <div style={{ position: 'relative' }}>
      <CarouselContainer ref={carouselRef} style={{ width: containerWidth }}>
        {images.map((image, index) => (
          <CarouselItem key={index}>
            <Image src={image} width={150} height={200} />
          </CarouselItem>
        ))}
      </CarouselContainer>
    </div>
  );
};


// 캐러셀 컨테이너 스타일링
const CarouselContainer = styled.div`
  display: flex;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    display: none; // 스크롤바 숨김
  }
`;

// 캐러셀 아이템 스타일링
const CarouselItem = styled.div`
  flex: none;
  scroll-snap-align: start;
  padding: 0; // 여기서 padding을 0으로 설정
`;

export default UserImageCarousel;
