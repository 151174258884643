import QueryString from "qs";
import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import userStorage from "../../utils/userStorage";
import { googleLoginAPI } from "../../utils/api/auth";

const GoogleLogin = () => {
  const location = useLocation();
  const navigator = useNavigate();
  const query = QueryString.parse(location.search, {
    ignoreQueryPrefix: true,
    // 문자열 맨 앞의 ?를 생력
  });

  const googleLogin = async () => {
    try {
      const { accessToken } = await googleLoginAPI({
        code: query.code as string,
        redirectUrl: process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI as string,
      });

      userStorage.set({ accessToken });
    } catch (e) {
      console.log(e);
    } finally {
      navigator("/");
    }
  };

  useEffect(() => {
    if (query.code) {
      googleLogin();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div>처리중....</div>;
};

export default GoogleLogin;
