import { Descriptions, Flex } from "antd";
import styled from "@emotion/styled";
import UserImageCarousel from "../../user/components/UserImageCarousel";
import MatchingViewBtn from "./MatchingViewBtn";
import { GetMatchedUserPriority } from "../../../utils/api/matching";

interface RecommendedProfileCardProps {
  partner: GetMatchedUserPriority; // 현재 조회하고 있는 유저에게 추천해준 유저
}

const PriorityProfileCardSecond = ({
  partner,
}: RecommendedProfileCardProps) => {
  return (
    <Section
      style={{
        backgroundColor: partner.refundUser ? "#f0f0f0" : undefined, // 연한 회색 배경
        color: partner.refundUser ? "#d9d9d9" : undefined, // 연한 글씨 색상
        opacity: partner.refundUser ? "0.5" : "1", // 투명도 조정
      }}
    >
      <FlexColumn>
        <Flex>
          {partner.bothRecommendation ? (
            <TwoWayRecommendedBadge>양방향 추천</TwoWayRecommendedBadge>
          ) : (
            <OneWayRecommendedBadge>일방향 추천</OneWayRecommendedBadge>
          )}
          <PriorityChoiceBadge>{partner.choiceRanking}</PriorityChoiceBadge>
        </Flex>
        <ProfileImages>
          <UserImageCarousel images={partner.userImages} />
        </ProfileImages>

        <Flex
          style={{
            paddingTop: "32px",
            alignItems: "flex-start",
          }}
        >
          {

            <MatchingViewBtn
              userName={partner.name}
              userId={partner.userId}
            />

          }
        </Flex>

        <div>
          <Descriptions column={2} title="유저 요약 정보">
            <Descriptions.Item label="이름">{partner.name}</Descriptions.Item>
            <Descriptions.Item label="나이">{partner.age}</Descriptions.Item>
            <Descriptions.Item label="키">{partner.height}</Descriptions.Item>
            <Descriptions.Item label="전화번호">{partner.phoneNumber}</Descriptions.Item>
            <Descriptions.Item label="직장">{partner.careerDetail}</Descriptions.Item>
            <Descriptions.Item label="외모 등급">
              {partner.appearanceRating}
            </Descriptions.Item>
            <Descriptions.Item label="능력 등급">
              {partner.careerRating}
            </Descriptions.Item>
            <Descriptions.Item label="종교">
              {partner.religion}
            </Descriptions.Item>
            <Descriptions.Item label="도시">
              {partner.address}
            </Descriptions.Item>
            <Descriptions.Item label="거주지">
              {partner.addressSubGroup}
            </Descriptions.Item>
            <Descriptions.Item label="흡연 / 음주 여부">
              {partner.smokingStatus} / {partner.drinkingStatus}
            </Descriptions.Item>
            <Descriptions.Item label="추천인">
              {partner.refereeName || "입력 전"}
            </Descriptions.Item>
            <Descriptions.Item label="유입 경로">
              {partner.funnel || "입력 전"}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </FlexColumn>
    </Section>
  );
};

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
`;

const Section = styled.div`
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  margin-bottom: 8px;
`;

const TwoWayRecommendedBadge = styled.span`
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75em;
  position: relative;
  right: 0px;
`;

const OneWayRecommendedBadge = styled.span`
  background-color: #e65272;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75em;
  position: relative;
  right: 0px;
`;

const PriorityChoiceBadge = styled.span`
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75em;
  position: relative;
  right: 0px;
  margin-left: 8px;
`;

const ProfileImages = styled.div`
  display: flex;
  overflow-x: auto;
  margin-bottom: 8px;
  margin-top: 8px;
  width: 100%;
  justify-content: center;
`;

export default PriorityProfileCardSecond;
