import { Layout, Menu, MenuProps } from "antd";
import React, { PropsWithChildren } from "react";
import { Link, useLocation } from "react-router-dom";

const { Header, Content, Footer } = Layout;
function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}

type MenuItem = Required<MenuProps>["items"][number];
const items: MenuItem[] = [
    getItem(<Link to="/matching">매칭 유저 목록</Link>, "/matching")
];

const MatchingLayout = ({ children }: PropsWithChildren) => {
    const location = useLocation();
    const path = location.pathname;
    return (
        <Layout className="layout">
            <Header>
                <Menu
                    theme="dark"
                    mode="horizontal"
                    defaultSelectedKeys={[path]}
                    items={items}
                />
            </Header>
            <Content style={{ padding: "20px 50px" }}>{children}</Content>
            <Footer style={{ textAlign: "center" }}>주선왕 ©2024</Footer>
        </Layout>
    );
};

export default MatchingLayout;
