import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { Button, Image, Input, InputNumber, Segmented, Select, Table, Typography, notification } from "antd";
import React, { useRef, useState, useEffect } from "react";
import { getUsers, getUserFilterCategory } from "../../utils/api/user";
import UserLayout from "./components/UserLayout";
import { useNavigate } from "react-router-dom";
import AppearanceUpdateBtnWithLabel from "../user/components/AppearanceUpdateBtnWithLabel";
import AppearanceImagesUpdateBtnWithLabel from "../user/components/AppearanceImagesUpdateBtnWithLabel";

const Users: React.FC = () => {
  const [filter, setFilter] = useState({
    gender: "MALE",
    name: "",
    minAge: 20,
    maxAge: 60,
    minHeight: 130,
    maxHeight: 200,
    appearanceRating: "",
    careerRating: "",
    smokingStatus: "",
    drinkingStatus: "",
    religion : "",
    address: "",
    addressSubGroup: "",
    refereeName: "",
    orderBy: ""
  });

  const onChangeFilter = (value: string | number, key: string) => {
    if (key === "address") {
      setFilter((prev) => ({
        ...prev,
        [key]: value as string,
        addressSubGroup: "",
      }));
      return;
    }
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const [sortCriteria, setSortCriteria] = useState({
    sortBy: "", // 정렬 기준
    sortOrder: "", // 정렬 (오름차순 또는 내림차순)
  });

  const onChangeSortCriteria = (value: string, type: "sortBy" | "sortOrder") => {
    setSortCriteria((prev) => ({
      ...prev,
      [type]: value,
    }));
  };

  useEffect(() => {
    const { sortBy, sortOrder } = sortCriteria;
    if (sortBy && sortOrder) {
      setFilter((prev) => ({
        ...prev,
        orderBy: `${sortBy}_${sortOrder}`,
      }));
    }
  }, [sortCriteria]);

  const { Search } = Input;
  const { Title } = Typography;
  const navigate = useNavigate();
  const {
    data: users,
    isLoading: isUsersLoading,
    isError: isUsersError,
  } = useQuery({
    queryKey: ["users", filter],
    queryFn: () =>
      getUsers({
        ...filter
      }),
  });


  const { data: userFilter } = useQuery({
    queryKey: ["userFilterCategory"],
    queryFn: () => getUserFilterCategory(),
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIconWhenUpdateAppearanceRating = (name: string, appearanceRating: any) => {
    api["success"]({
      message: "외모 등급 수정 완료!",
      description: `${name}님의 외모 등급이 ${appearanceRating} 등급으로 변경되었어요.`,
      duration: 3,
    });
  };

  const openNotificationWithIconWhenUpdateUserImages = (name: string) => {
    api["success"]({
      message: "대표 사진 수정 완료!",
      description: `${name}님의 대표 사진이 변경되었어요.`,
      duration: 3,
    });
  };


  const userBoxRef = useRef<HTMLDivElement>(null);
  const onScrollTop = () => {
    if (userBoxRef.current) userBoxRef.current.scrollTo(0, 0);
  };

  if (isUsersError || !userFilter) return <div>에러</div>;

  // refundUser에 따라 행에 클래스를 동적으로 할당하는 함수
  const getRowClassName = (record: any) => {
    return record.refundUser ? 'refundUserRow' : '';
  };

  return (
    <UserLayout>
      {contextHolder}
      <Paper>
        <Title level={2}>주선왕 회원 리스트</Title>
        <div
          style={{
            marginBottom: 12,
            width: "500px",
          }}
        >
          <Search
            placeholder="유저명을 검색해주세요"
            allowClear
            enterButton="검색"
            size="large"
            onChange={(e) => onChangeFilter(e.target.value, "name")}
            value={filter.name}
            onSearch={(value) => navigate(`/users?search=${value}`)}
          />
        </div>
        <FilterBox>
          <FlexColumn>
            <Flex
              style={{
                padding: "16px",
              }}
            >
              <div>
                <Typography.Title level={5}>성별</Typography.Title>
                <Segmented
                  value={filter.gender}
                  onChange={(value) => {
                    setFilter((prev) => ({
                      ...prev,
                      gender: value as "MALE" | "FEMALE",
                    }));
                  }}
                  options={[
                    {
                      label: "남자",
                      value: "MALE",
                    },
                    {
                      label: "여자",
                      value: "FEMALE",
                    },
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>외모 등급</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="외모 레벨"
                  style={{ width: 150 }}
                  value={filter.appearanceRating}
                  onChange={(value) => onChangeFilter(value, "appearanceRating")}
                  options={[
                    { value: "", label: "전체" },
                    ...userFilter.appearanceRatings.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>능력 등급</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="능력 등급"
                  style={{ width: 150 }}
                  value={filter.careerRating}
                  onChange={(value) => onChangeFilter(value, "careerRating")}
                  options={[
                    { value: "", label: "전체" },
                    ...userFilter.careerRatings.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>지역 (시,도)</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="지역 (시,도)"
                  style={{ width: 150 }}
                  value={filter.address}
                  onChange={(value) => onChangeFilter(value, "address")}
                  options={[
                    { value: "", label: "전체" },
                    { value: "서울", label: "서울" },
                    { value: "수도권", label: "수도권" },
                    { value: "비수도권", label: "비수도권" },
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>지역 (시,군,구)</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="지역 (시,군,구)"
                  style={{ width: 150 }}
                  disabled={!filter.address}
                  value={filter.addressSubGroup}
                  onChange={(value) => onChangeFilter(value, "addressSubGroup")}
                  options={
                    filter.address
                      ? [
                        { value: "", label: "전체" },
                        ...userFilter.addressGroupMap[
                          filter.address as "서울" | "수도권"
                        ].addressSubGroups.map((data) => ({
                          value: data,
                          label: data,
                        })),
                      ]
                      : []
                  }
                />
              </div>
              <div>
                <Typography.Title level={5}>흡연</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="흡연"
                  style={{ width: 150 }}
                  value={filter.smokingStatus}
                  onChange={(value) => onChangeFilter(value, "smokingStatus")}
                  options={[
                    { value: "", label: "전체" },
                    ...userFilter.smokingStatuses.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>음주</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="음주"
                  style={{ width: 150 }}
                  value={filter.drinkingStatus}
                  onChange={(value) => onChangeFilter(value, "drinkingStatus")}
                  options={[
                    { value: "", label: "전체" },
                    ...userFilter.drinkingStatuses.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>종교</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="종교"
                  style={{ width: 150 }}
                  value={filter.religion}
                  onChange={(value) => onChangeFilter(value, "religion")}
                  options={[
                    { value: "", label: "전체" },
                    ...userFilter.religions.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
            </Flex>
            <Flex
              style={{
                marginBottom: "36px",
                gap: "28px",
              }}
            >
              <div>
                <Typography.Title level={5}>키 범위</Typography.Title>
                <InputNumber
                  min={130}
                  max={200}
                  value={filter.minHeight}
                  onChange={(value) => onChangeFilter(value as number, "minHeight")}
                />
                cm ~{" "}
                <InputNumber
                  min={130}
                  max={200}
                  defaultValue={200}
                  value={filter.maxHeight}
                  onChange={(value) => onChangeFilter(value as number, "maxHeight")}
                />
                cm
              </div>
              <div>
                <Typography.Title level={5}>나이 범위</Typography.Title>
                <InputNumber
                  min={20}
                  max={60}
                  defaultValue={20}
                  value={filter.minAge}
                  onChange={(value) => onChangeFilter(value as number, "minAge")}
                />
                세 ~{" "}
                <InputNumber
                  min={20}
                  max={60}
                  defaultValue={60}
                  value={filter.maxAge}
                  onChange={(value) => onChangeFilter(value as number, "maxAge")}
                />
                세
              </div>
              <div>
                <Typography.Title level={5}>추천인</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="추천인"
                  style={{ width: 150 }}
                  value={filter.refereeName}
                  onChange={(value) => onChangeFilter(value, "refereeName")}
                  options={[
                    { value: "", label: "전체" },
                    ...userFilter.refereeNames.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>정렬 기준</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="정렬 기준을 선택하세요"
                  style={{ width: 150 }}
                  value={sortCriteria.sortBy}
                  onChange={(value) => onChangeSortCriteria(value, "sortBy")}
                  options={[
                    { value: "", label: "전체" },
                    ...Object.entries(userFilter.orderFilterNameMap).map(([key, label]) => ({
                      value: label, // 키를 value로 사용
                      label: key, // 실제 표시될 값으로 label 사용
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>정렬</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="정렬 기준을 선택하세요"
                  style={{ width: 150 }}
                  value={sortCriteria.sortOrder}
                  onChange={(value) => onChangeSortCriteria(value, "sortOrder")}
                  options={[
                    { value: "", label: "전체" },
                    ...Object.entries(userFilter.orderMenu).map(([key, label]) => ({
                      value: label, // 키를 value로 사용
                      label: key, // 실제 표시될 값으로 label 사용
                    })),
                  ]}
                />
              </div>
            </Flex>
          </FlexColumn>
        </FilterBox>
        (*사진은 클릭해서 보세요!)
        {!!users && (

          <StyledTable
            pagination={{
              pageSize: 10,
              onChange: onScrollTop,
              position: ["bottomLeft"],
            }}
            loading={isUsersLoading}
            bordered
            columns={[
              {
                title: "진행도",
                dataIndex: "registrationStep",
                width: 100,
                render: (name, record) => (
                  <FlexColumn style={{ color: record.refundUser ? 'red' : 'black' }}>
                    {!record.refundUser ?
                      record.registrationStep : "환불 유저"
                    }
                  </FlexColumn>
                )
              },
              {
                title: "성별",
                dataIndex: "gender",
                width: 80,
              },
              {
                title: "나이",
                dataIndex: "age",
                width: 80,
              },
              {
                title: "이름",
                dataIndex: "name",
                align: "center",
                width: 180,
                render: (name, record) => (
                  <FlexColumn>
                    {name}
                    <Button type="primary">
                      <a href={`${process.env.REACT_APP_JUSUNKING_URL}detail?id=${record.kakaoAppUserId}`}>
                        회원 프로필 페이지 보기
                      </a>
                    </Button>
                  </FlexColumn>

                ),
              },
              {
                title: "사진1",
                dataIndex: "userImages",
                width: 280,
                align: "center",

                render: (imageURL) => <Image src={imageURL[0]} />,
              },
              {
                title: "사진2",
                dataIndex: "userImages",
                width: 280,
                align: "center",
                render: (imageURL) => <Image src={imageURL[1]} />,
              },
              {
                title: "사진3",
                dataIndex: "userImages",
                width: 280,
                align: "center",
                render: (imageURL) => <Image src={imageURL[2]} />,
              },
              {
                title: "외모 레벨",
                dataIndex: "appearanceRating",
                width: 160,
                render: (_, record) => (
                  <div>
                    {(record.appearanceRating !== "등급 분류 전" && record.refundUser === false) ?
                      <FlexColumn>
                        <AppearanceUpdateBtnWithLabel
                          userId={record.userId}
                          appearanceRating={record.appearanceRating}
                          userName={record.name}
                          userImages={record.userImages}
                          openNotificationWithIcon={openNotificationWithIconWhenUpdateAppearanceRating}
                        />
                        <AppearanceImagesUpdateBtnWithLabel
                          userProfileId={record.userProfileId}
                          userImages={record.userImages}
                          userName={record.name}
                          openNotificationWithIcon={openNotificationWithIconWhenUpdateUserImages}
                        />
                      </FlexColumn>
                      : record.appearanceRating
                    }
                  </div>
                ),
              },
              {
                title: "직장",
                dataIndex: "careerDetail",
                width: 120,
              },
              {
                title: "직업군",
                dataIndex: "careerType",
                width: 120,
              },
              {
                title: "능력 레벨",
                dataIndex: "careerRating",
                width: 120,
              },

              {
                title: "대학",
                dataIndex: "univName",
                width: 120,
                render(value) {
                  return value ? value : "입력 안함";
                },
              },
              {
                title: "대학 레벨",
                dataIndex: "univRating",
                width: 120,
              },
              {
                title: "주소",
                dataIndex: "address",
                width: 160,
              },

              {
                title: "키",
                dataIndex: "height",
                width: 80,
              },

              {
                title: "연봉",
                dataIndex: "income",
                // 한국돈 만원 단위로 표시
                render: (value) =>
                  value
                    ? `${(value / 10000).toLocaleString()} 만원`
                    : "입력 안함",
                width: 140,
              },
              {
                title: "핸드폰번호",
                dataIndex: "phoneNumber",
                width: 160,
              },
              { title: "종교", dataIndex: "religion", width: 80 },
              { title: "특징", dataIndex: "referencePoint", width: 110 },
              {
                title: "자기소개",
                dataIndex: "appealPoint",
                width: 220,
              },
              {
                title: "카카오 아이디",
                dataIndex: "kakaoAppUserId",
                width: 120,
              },
              {
                title: "카카오 이메일",
                dataIndex: "kakaoEmail",
                width: 200,
              },
              {
                title: "카카오 닉네임",
                dataIndex: "kakaoNickname",
                width: 80,
              },
            ]}
            rowClassName={getRowClassName}
            dataSource={users}
            scroll={{ x: "calc(700px + 50%)", y: 640 }}
          />
        )}
      </Paper>

    </UserLayout>
  );
};

const Paper = styled.div`
  padding: 16px;
  background-color: white;
  border-radius: 12px;
`;

const FilterBox = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  margin: 16px 0;
  padding: 16px;
`;

const Flex = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
`;

const StyledTable = styled(Table)`
  .refundUserRow {
    background-color: #f0f0f0; // 연한 회색 배경
    color: black; // 연한 글씨 색상
    opacity: "0.5"; // 투명도 조정
    /* refundUserRow 클래스가 적용된 행에 대한 hover 스타일 비활성화 */
    &:hover {
      background-color: #cccccc !important; /* hover 시에도 배경색을 유지 */
      color: #666666 !important; /* hover 시에도 텍스트 색상을 유지 */
    }
  }
`;

export default Users;
