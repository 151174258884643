import { Layout, Menu, MenuProps } from "antd";
import React, { PropsWithChildren } from "react";
import { Link, useLocation } from "react-router-dom";

const { Header, Content, Footer } = Layout;
function getItem(
  label: React.ReactNode,
  key: React.Key,
  icon?: React.ReactNode,
  children?: MenuItem[],
  type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

type MenuItem = Required<MenuProps>["items"][number];
const items: MenuItem[] = [
  getItem(<Link to="/users">유저 목록</Link>, "/users"),
  getItem(
    <Link to="/users/appearance">유저 외모 분류</Link>,
    "/users/appearance"
  ),
  getItem(<Link to="/users/career">유저 능력 분류</Link>, "/users/career"),
  getItem(<Link to="/users/univ">유저 학교 분류</Link>, "/users/univ"),
];

const UserLayout = ({ children }: PropsWithChildren) => {
  const location = useLocation();
  const path = location.pathname;
  return (
    <Layout className="layout">
      <Header>
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={[path]}
          items={items}
        />
      </Header>
      <Content style={{ padding: "20px 50px" }}>{children}</Content>
      <Footer style={{ textAlign: "center" }}>주선왕 ©2024</Footer>
    </Layout>
  );
};

export default UserLayout;
