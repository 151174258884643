import React, {PropsWithChildren} from "react";
import {Layout,
    // Menu,
    // MenuProps
} from "antd";
// import {Link} from "react-router-dom";

const {
    // Header,
    Content, Footer} = Layout;

// function getItem(
//     label: React.ReactNode,
//     key: React.Key,
//     icon?: React.ReactNode,
//     children?: MenuItem[],
//     type?: "group"
// ): MenuItem {
//     return {
//         key,
//         icon,
//         children,
//         label,
//         type,
//     } as MenuItem;
// }

// type MenuItem = Required<MenuProps>["items"][number];
// const items: MenuItem[] = [
//     getItem(<Link to="/">주선왕</Link>, "/"),
//     getItem(<Link to="/recommendations">첫 추천 큐 목록 조회</Link>, "/recommendations"),
//     // getItem(<Link to="/recommendations/statistics">첫 추천 통계</Link>, "/recommendations/statistics"),
// ];

const RecommendationLayout = ({children}: PropsWithChildren) => {
    // const location = useLocation();
    // const path = location.pathname;
    return (
        <Layout className="layout">
            {/*<Header>*/}
            {/*    <Menu*/}
            {/*        theme="dark"*/}
            {/*        mode="horizontal"*/}
            {/*        defaultSelectedKeys={[path]}*/}
            {/*        items={items}*/}
            {/*    />*/}
            {/*</Header>*/}
            <Content>
                <div className="site-layout-content" style={{background: "white"}}>
                    {children}
                </div>
            </Content>
            <Footer style={{textAlign: "center"}}>주선왕 ©2024</Footer>
        </Layout>
    );
};

// const Logo = styled.div`
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     height: 64px;
//     font-size: 18px;
//     font-weight: 600;
//     color: #fff;
//     background-color: #000;
// `;

export default RecommendationLayout;
