import "./App.css";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import {Link, Outlet, useLocation} from "react-router-dom";
import styled from "@emotion/styled";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import {Button, Menu, MenuProps, Layout} from "antd";
import userStorage from "./utils/userStorage";
import React, { useEffect, useState } from "react";
import {Header} from "antd/es/layout/layout";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 0,
      refetchOnWindowFocus: false,
    },
  },
});

const handleLogin = () => {
  // 구글 로그인 화면으로 이동시키기
  window.location.href = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${process.env.REACT_APP_GOOGLE_AUTH_CLIENT_ID}&redirect_uri=${process.env.REACT_APP_GOOGLE_AUTH_REDIRECT_URI}&response_type=code&scope=email`;
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: "group"
): MenuItem {
  return {
    key,
    icon,
    children,
    label,
    type,
  } as MenuItem;
}

type MenuItem = Required<MenuProps>["items"][number];
const items: MenuItem[] = [
  getItem(<Link to="/">주선왕</Link>, "/"),
  getItem(<Link to="/recommendations">주차별 첫 추천 조회</Link>, "/recommendations"),
  getItem(<Link to="/">주차별 추가 추천 조회</Link>, "/")
];

function App() {
  const [isLogin, setIsLogin] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (!!userStorage.get()) setIsLogin(true);
  }, [location.pathname]);

  const path = location.pathname;

  return (
    <QueryClientProvider client={queryClient}>
      {isLogin ? (
        <Layout>
          {/*<SideBar />*/}
          <Header>
            <Menu
                theme="dark"
                mode="horizontal"
                defaultSelectedKeys={[path]}
                items={items}
            />
          </Header>
          <Outlet />
        </Layout>
      ) : (
        <NonLoginLayout>
          <div>
            <Button danger size="large" onClick={handleLogin}>
              구글 로그인
            </Button>
          </div>
          <Outlet />
        </NonLoginLayout>
      )}
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  );
}

const NonLoginLayout = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export default App;
