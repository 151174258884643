import React from 'react';
import {useQuery} from "@tanstack/react-query";
import {useNavigate, useParams} from "react-router-dom";
import {getRecommendedUserProfile, PartnerUser} from "../../utils/api/recommend";
import {Card, Image, Space, Table, Tag, Typography} from 'antd';
import styled from "@emotion/styled";
import MatchLayout from "./components/RecommendationLayout";
import type {ColumnsType} from "antd/es/table";
import {formatKoreanMoney, getTagColor} from "./RecommendationHome";
import RecommendedUserProfileCard from "./components/RecommendedUserProfileCard";

const RecommendationDetail = () => {
    const navigate = useNavigate();
    const params = useParams<{ id: string }>();

    const {data: recommendationUserQueryResult} = useQuery({
        queryKey: ["matching-users", params.id],
        queryFn: () => getRecommendedUserProfile({
            name: "",
            userId: params.id
        }),
        select: (data) => ({
            contents: data.data
        })
    });

    const content = recommendationUserQueryResult?.contents;
    const receivedRecommendations = content?.receivedRecommendations;
    const receivedUpperLevelRecommendations = receivedRecommendations?.filter(
        (recommendation) => recommendation.recommendationLevel === "미끼 추천"
    );
    const receivedSameLevelRecommendations = receivedRecommendations?.filter(
        (recommendation) => recommendation.recommendationLevel === "동급 추천"
    );

    const sentRecommendations = content?.sentRecommendations;
    const sentUpperLevelRecommendations = sentRecommendations?.filter(
        (recommendation) => recommendation.recommendationLevel === "미끼 추천"
    );
    const sentSameLevelRecommendations = sentRecommendations?.filter(
        (recommendation) => recommendation.recommendationLevel === "동급 추천"
    );

    const columns: ColumnsType<PartnerUser> = [
        {
            title: '매력 정보',
            align: 'center',
            children: [
                {
                    title: '프로필',
                    key: 'profile',
                    width: 250,
                    align: 'center',
                    render: (_, record) => (
                        <div onClick={(e) => e.stopPropagation()}>
                            <Image
                                src={record.userProfileImages[0]}
                                height={200}
                                preview={{
                                    width: 800,  // 프리뷰 모달의 너비
                                    height: 600, // 프리뷰 모달의 높이
                                    mask: <div>클릭하여 크게보기</div>, // 선택적: 호버시 보여줄 텍스트
                                }}
                            />
                        </div>
                    )
                }
            ]
        },
        {
            title: '기본 정보',
            align: 'center',
            children: [
                {
                    title: () => (
                        <div>
                            <div>이름</div>
                        </div>
                    ),
                    key: 'name',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div>{record.name}</div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>나이</div>
                        </div>
                    ),
                    key: 'age',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div>{record.age}세</div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>키</div>
                        </div>
                    ),
                    key: 'height',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div>{record.height}cm</div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>외모</div>
                        </div>
                    ),
                    key: 'appearanceRating',
                    width: 100,
                    render: (_, record) => (
                        <Space direction="vertical">
                            <Tag
                                color={getTagColor(record.appearanceRating, 'APPEARANCE_RATING')}>외모 {record.appearanceRating}</Tag>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>능력</div>
                        </div>
                    ),
                    key: 'overallSocialRating',
                    width: 100,
                    render: (_, record) => (
                        <Space direction="vertical">
                            <Tag
                                color={getTagColor(record.overallSocialRating, 'OVERALL_SOCIAL_RATING')}>능력 {record.overallSocialRating}</Tag>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>학력</div>
                        </div>
                    ),
                    key: 'univRating',
                    width: 100,
                    render: (_, record) => (
                        <Space direction="vertical">
                            <Tag color={getTagColor(record.univRating, 'UNIV_RATING')}>학력 {record.univRating}</Tag>
                        </Space>
                    )
                }
            ]
        },
        {
            title: '직업/학력 정보',
            align: 'center',
            children: [{
                title: () => (
                    <div>
                        <div>직업</div>
                    </div>
                ),
                key: 'career',
                width: 180,
                align: 'center',
                render: (_, record) => (
                    <Space direction="vertical">
                        <div>{record.careerDetail}</div>
                        <div>{record.careerSubGroup}</div>
                    </Space>
                )
            }, {
                title: () => (
                    <div>
                        <div>대학</div>
                    </div>
                ),
                key: 'career',
                width: 180,
                align: 'center',
                render: (_, record) => (
                    <Space direction="vertical">
                        <div>{record.univName !== "" ? record.univName : "-"}</div>
                    </Space>
                )
            },
                {
                    title: () => (
                        <div>
                            <div>연봉</div>
                        </div>
                    ),
                    key: 'career',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div>{record.income != null ? formatKoreanMoney(record.income) : "-"}</div>
                        </Space>
                    )
                }
            ]
        },
        {
            align: 'center',
            children: [
                {
                    title: () => (
                        <div>
                            <div>성향 정보</div>
                        </div>
                    ),
                    key: 'preference',
                    width: 180,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>종교</b>
                                </div>
                                {record.religion ?? "-"}
                            </div>
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>흡연</b>
                                </div>
                                {record.smokingStatus}
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{width: '40px'}}>
                                    <b>음주</b>
                                </div>
                                {record.drinkingStatus}
                            </div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>이상형 정보</div>
                        </div>
                    ),
                    key: 'preferenceType',
                    align: 'center',
                    width: 180,
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>타입</b>
                                </div>
                                <Tag
                                    color={getTagColor(record.preferenceType, 'PREFERENCE_TYPE')}>{record.preferenceType}</Tag>
                            </div>
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>나이</b>
                                </div>
                                {record.age}
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>키</b>
                                </div>
                                {record.height}
                            </div>
                        </Space>
                    )
                },
            ]
        },
        {
            title: "추천 정보",
            children: [
                {
                    title: () => (
                        <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                            <div>미끼 추천</div>
                            <div style={{fontSize: '12px', color: '#808080'}}>추천 수 / 가능 수</div>
                        </div>
                    ),
                    key: 'upperLevelRecommendation',
                    width: 180,
                    align: 'center',
                    render: (_, record) => {
                        return (
                            <Space direction="horizontal">
                                <b>{record?.numOfUpperLevelToRecommendations}</b> / 0~3
                            </Space>
                        )
                    }
                },
                {
                    title: () => (
                        <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                            <div>동급 추천</div>
                            <div style={{fontSize: '12px', color: '#808080'}}>추천 수 / 가능 수</div>
                        </div>
                    ),
                    key: 'sameLevelRecommendation',
                    width: 180,
                    align: 'center',
                    render: (_, record) => {
                        return (
                            <Space direction="horizontal">
                                <b>{record?.numOfSameLevelToRecommendations}</b> / 5~8
                            </Space>
                        )
                    }
                }
            ]
        }
    ];

    const handleRowClick = (record: PartnerUser) => {
        navigate(`/recommendations/${record.id}`);
    };

    return (
        <MatchLayout>
            <Card>
                {content && <RecommendedUserProfileCard data={content}/>}
            </Card>

            <Card style={{marginBottom: '16px'}}>
                <Typography.Title level={2}>{content?.name}님이 추천 받은 목록</Typography.Title>
                <SectionCard title={
                    <>
                        미끼 추천 유저 목록 : {receivedUpperLevelRecommendations?.length}/3 (미끼 추천은 0~3개)
                    </>
                }>
                    <Table
                        columns={columns}
                        dataSource={receivedUpperLevelRecommendations?.map((item, index) => {
                            let partnerUser = item.partnerUser;
                            return ({
                                ...partnerUser,
                                key: partnerUser.id,
                                index: index + 1
                            });
                        })}
                        rowKey={Math.random}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{x: 1500}}
                        pagination={false}
                        style={{
                            '--ant-table-border-color': '#f0f0f0'  // 테두리 색상 변경
                        } as React.CSSProperties}
                    />
                </SectionCard>

                <SectionCard title={
                    <>
                        동급 추천 유저 목록 : {receivedSameLevelRecommendations?.length}/8 (동급 추천은 5~8개)
                    </>
                }>
                    <Table
                        columns={columns}
                        dataSource={receivedSameLevelRecommendations?.map((item, index) => {
                            let partnerUser = item.partnerUser;
                            return ({
                                ...partnerUser,
                                key: partnerUser.id,
                                index: index + 1
                            });
                        })}
                        rowKey={Math.random}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{x: 1500}}
                        pagination={false}
                    />
                </SectionCard>
            </Card>

            <Card>
                <Typography.Title level={2}>{content?.name}님을 추천 받은 목록</Typography.Title>
                <SectionCard title={
                    <>
                        미끼 추천 프로필로 사용 : {sentUpperLevelRecommendations?.length}
                    </>
                }>
                    <Table
                        columns={columns}
                        dataSource={sentUpperLevelRecommendations?.map((item, index) => {
                            let partnerUser = item.partnerUser;
                            return ({
                                ...partnerUser,
                                key: partnerUser.id,
                                index: index + 1
                            });
                        })}
                        rowKey={Math.random}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{x: 1500}}
                        pagination={false}
                        style={{
                            '--ant-table-border-color': '#f0f0f0'  // 테두리 색상 변경
                        } as React.CSSProperties}
                    />
                </SectionCard>

                <SectionCard title={
                    <>
                        동급 추천 프로필로 사용 : {sentSameLevelRecommendations?.length}
                    </>
                }>
                    <Table
                        columns={columns}
                        dataSource={sentSameLevelRecommendations?.map((item, index) => {
                            let partnerUser = item.partnerUser;
                            return ({
                                ...partnerUser,
                                key: partnerUser.id,
                                index: index + 1
                            });
                        })}
                        rowKey={Math.random}
                        onRow={(record) => ({
                            onClick: () => handleRowClick(record), // 클릭 이벤트
                            style: {cursor: 'pointer'} // 커서 스타일
                        })}
                        scroll={{x: 1500}}
                        pagination={false}
                    />
                </SectionCard>
            </Card>

        </MatchLayout>
    );
};

const SectionCard = styled(Card)`
    margin-bottom: 24px;

    .ant-card-head-title {
        display: flex;
        align-items: center;
        gap: 8px;
    }
`;

export default RecommendationDetail;