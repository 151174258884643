import React, {useEffect, useState, useCallback} from 'react';
import {Card, Image, Select, Space, Table, TableProps, Tag, Tooltip} from 'antd';
import type {ColumnsType} from 'antd/es/table';
import styled from '@emotion/styled';
import {
    getRecommendedUsers,
    Pagination,
    Recommendation,
    RecommendedUser,
    RecommendedUsersResponse
} from "../../utils/api/recommend";
import {useQuery} from "@tanstack/react-query";
import {useNavigate, useSearchParams} from 'react-router-dom';
import MatchLayout from "./components/RecommendationLayout";
import RecommendationGlobalTableFilter from "./components/RecommendationGlobalTableFilter";


interface QueryResult {
    contents: RecommendedUser[];
    pagination: Pagination;
}

interface GroupedRecommendations {
    [key: string]: Recommendation[];
}

export interface PreferenceOption {
    value: string;
    label: string;
    description?: string | null;
}

export const FILTER_OPTIONS = {
    APPEARANCE_RATING: {
        ALL: {value: '', label: '전체'},
        FIRST_GRADE: {value: 'FIRST_GRADE', label: '상'},
        SECOND_GRADE: {value: 'SECOND_GRADE', label: '중상'},
        THIRD_GRADE: {value: 'THIRD_GRADE', label: '중'},
    },
    OVERALL_SOCIAL_RATING: {
        ALL: {value: '', label: '전체'},
        FIRST_GRADE: {value: 'FIRST_GRADE', label: '1등급'},
        SECOND_GRADE: {value: 'SECOND_GRADE', label: '2등급'},
        THIRD_GRADE: {value: 'THIRD_GRADE', label: '3등급'},
        FOURTH_GRADE: {value: 'FOURTH_GRADE', label: '4등급', description: '여성 외모 상,중상의 경우 => 능력 4,5등급도 허용'},
        FIFTH_GRADE: {value: 'FIFTH_GRADE', label: '5등급', description: '여성 외모 상,중상의 경우 => 능력 4,5등급도 허용'},
    },
    UNIV_RATING: {
        ALL: {value: '', label: '전체'},
        FIRST_GRADE: {value: 'FIRST_GRADE', label: '1등급'},
        SECOND_GRADE: {value: 'SECOND_GRADE', label: '2등급'},
        THIRD_GRADE: {value: 'THIRD_GRADE', label: '3등급'},
        FOURTH_GRADE: {value: 'FOURTH_GRADE', label: '4등급'},
        FIFTH_GRADE: {value: 'FIFTH_GRADE', label: '5등급'},
    },
    PREFERENCE_TYPE: {
        ALL: {value: '', label: '전체'},
        NORMAL: {
            value: 'NORMAL',
            label: '일반 타입',
            description: '남성: 외모 중심 선호 | 여성: 능력 중심 선호'
        },
        BALANCE: {
            value: 'BALANCE',
            label: '밸런스 타입',
            description: '밸런스 중심(능력+학력+외모)'
        }
    },
    GENDER: {
        ALL: {value: '', label: '전체'},
        MALE: {value: 'MALE', label: '남성'},
        FEMALE: {value: 'FEMALE', label: '여성'},
    }
};

export const getTagColor = (type: string, category: string) => {
    switch (category) {
        case 'APPEARANCE_RATING':
            switch (type) {
                case '상':
                    return 'red';
                case '중상':
                    return 'orange';
                case '중':
                    return 'gold';
                default:
                    return 'default';
            }

        case 'OVERALL_SOCIAL_RATING':
            switch (type) {
                case '1등급':
                    return 'blue';
                case '2등급':
                    return 'cyan';
                case '3등급':
                    return 'green';
                case '4등급':
                    return 'orange';
                case '5등급':
                    return 'red';
                default:
                    return 'default';
            }

        case 'UNIV_RATING':
            switch (type) {
                case '1등급':
                    return 'blue';
                case '2등급':
                    return 'cyan';
                case '3등급':
                    return 'green';
                case '4등급':
                    return 'orange';
                case '5등급':
                    return 'red';
                default:
                    return 'default';
            }

        case 'PREFERENCE_TYPE':
            switch (type) {
                case '외모형':
                    return 'gold';
                case '능력형':
                    return 'gold';
                case '밸런스형':
                    return 'processing';
                default:
                    return 'default';
            }

        default:
            return 'default';
    }
};

export function formatKoreanMoney(amount: number) {
    // 금액이 0이거나 유효하지 않은 경우 처리
    if (!amount) return '0원';

    // 한국어 단위 정의
    const units = ['', '만', '억', '조'];
    const numbers = ['영', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];

    // 숫자를 문자열로 변환하고 절대값 사용
    const numStr = Math.abs(amount).toString();

    // 4자리씩 끊어서 배열로 변환
    const groups = [];
    for (let i = numStr.length; i > 0; i -= 4) {
        groups.unshift(numStr.slice(Math.max(0, i - 4), i));
    }

    let result = '';
    groups.forEach((group, index) => {
        const unit = units[groups.length - 1 - index];
        const groupNum = parseInt(group);

        if (groupNum !== 0) {
            // 1000 단위 처리
            if (groupNum >= 1000) {
                const thousand = Math.floor(groupNum / 1000);
                result += (thousand === 1 ? '' : numbers[thousand]) + '천';
            }

            // 100 단위 처리
            if (groupNum % 1000 >= 100) {
                const hundred = Math.floor((groupNum % 1000) / 100);
                result += (hundred === 1 ? '' : numbers[hundred]) + '백';
            }

            // 10 단위 처리
            if (groupNum % 100 >= 10) {
                const ten = Math.floor((groupNum % 100) / 10);
                result += (ten === 1 ? '' : numbers[ten]) + '십';
            }

            // 1 단위 처리
            if (groupNum % 10 > 0) {
                result += numbers[groupNum % 10];
            }

            result += unit;
        }
    });

    return result + '원';
}


const RecommendationHome: React.FC = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();

    // URL에서 초기 필터 값을 가져오는 함수
    const getInitialFilter = useCallback(() => {
        return {
            gender: searchParams.get('gender') || "MALE",
            name: searchParams.get('name') || "",
            page: parseInt(searchParams.get('page') || '0'),
            pageSize: parseInt(searchParams.get('pageSize') || '10'),
            overallSocialRating: searchParams.get('overallSocialRating') || "",
            appearanceRating: searchParams.get('appearanceRating') || "",
            univRating: searchParams.get('univRating') || "",
            preferenceType: searchParams.get('preferenceType') || "",
            userId: searchParams.get('userId') || ""
        };
    }, [searchParams]);


    const [filter, setFilter] = useState(getInitialFilter());

    // 필터 변경시 URL 업데이트
    const updateURLParameters = (newFilter: typeof filter) => {
        const params = new URLSearchParams();
        Object.entries(newFilter).forEach(([key, value]) => {
            if (value !== "") {
                params.set(key, value.toString());
            }
        });
        setSearchParams(params);
    };

    const handleFilterChange = (values: Partial<typeof filter>) => {
        const newFilter = {
            ...filter,
            ...values,
            page: 0 // 필터 변경 시 첫 페이지로 이동
        };
        setFilter(newFilter);
        updateURLParameters(newFilter);
    };

    const handlePageChange = (page: number, pageSize?: number) => {
        const newFilter = {
            ...filter,
            page: page - 1,
            pageSize: pageSize || filter.pageSize
        };
        setFilter(newFilter);
        updateURLParameters(newFilter);
    };

    const handleReset = () => {
        const resetFilter = {
            gender: "",
            name: "",
            page: 0,
            pageSize: 10,
            overallSocialRating: "",
            appearanceRating: "",
            univRating: "",
            preferenceType: "",
            userId: ""
        };
        setFilter(resetFilter);
        // URL 파라미터 초기화
        setSearchParams({});
    };

    // URL 변경 감지하여 필터 상태 업데이트
    useEffect(() => {
        const currentFilter = getInitialFilter();
        setFilter(currentFilter);
    }, [getInitialFilter]);



    const {data: recommendationUserQueryResult} = useQuery<RecommendedUsersResponse, Error, QueryResult>({
        queryKey: ["matching-users", filter],
        queryFn: () =>
            getRecommendedUsers({
                ...filter
            }),
        select: (data) => ({
            contents: data.data.contents,
            pagination: data.data.pagination
        })
    });

    const handleRowClick = (record: RecommendedUser) => {
        navigate(`/recommendations/${record.id}`);
    };

    const columns: ColumnsType<RecommendedUser> = [
        {
            children: [
                {
                    title: '첫 추천 완료',
                    key: 'isFirstRecommendationDone',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <div>{record.receivedRecommendations.length >= 8 ?
                            <Tag color="#87d068">SUCCESS</Tag>
                            : <Tag color="#f50">FAIL</Tag>}</div>
                    )
                },
                {
                    title: 'ID',
                    key: 'id',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <div onClick={(e) => e.stopPropagation()}>{record.id}</div>
                    )
                },
            ]
        },
        {
            title: '매력 정보',
            align: 'center',
            children: [
                {
                    title: '프로필',
                    key: 'profile',
                    width: 250,
                    align: 'center',
                    render: (_, record) => (
                        <div onClick={(e) => e.stopPropagation()}>
                            <Image
                                src={record.userProfileImages[0]}
                                height={200}
                                preview={{
                                    width: 800,  // 프리뷰 모달의 너비
                                    height: 600, // 프리뷰 모달의 높이
                                    mask: <div>클릭하여 크게보기</div>, // 선택적: 호버시 보여줄 텍스트
                                }}
                            />
                        </div>
                    )
                }
            ]
        },
        {
            title: '기본 정보',
            align: 'center',
            children: [
                {
                    title: () => (
                        <div>
                            <div>이름</div>
                        </div>
                    ),
                    key: 'name',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div>{record.name}</div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>나이</div>
                        </div>
                    ),
                    key: 'age',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div>{record.age}세</div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>키</div>
                        </div>
                    ),
                    key: 'height',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div>{record.height}cm</div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>성별</div>
                            <Space>
                                <Select
                                    style={{width: 80}}
                                    size="small"
                                    value={filter.gender}
                                    onChange={(value) => handleFilterChange({gender: value})}
                                >
                                    {Object.values(FILTER_OPTIONS.GENDER).map(({value, label}) => (
                                        <Select.Option key={value} value={value}>{label}</Select.Option>
                                    ))}
                                </Select>
                            </Space>
                        </div>
                    ),
                    key: 'gender',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div>{record.gender}</div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>외모</div>
                            <Space>
                                <Select
                                    style={{width: 80}}
                                    size="small"
                                    value={filter.appearanceRating}
                                    onChange={(value) => handleFilterChange({appearanceRating: value})}
                                >
                                    {Object.values(FILTER_OPTIONS.APPEARANCE_RATING).map(({value, label}) => (
                                        <Select.Option key={value} value={value}>{label}</Select.Option>
                                    ))}
                                </Select>
                            </Space>
                        </div>
                    ),
                    key: 'appearanceRating',
                    width: 100,
                    render: (_, record) => (
                        <Space direction="vertical">
                            <Tag
                                color={getTagColor(record.appearanceRating, 'APPEARANCE_RATING')}>외모 {record.appearanceRating}</Tag>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>능력</div>
                            <Space>
                                <Select
                                    placeholder="능력"
                                    size="small"
                                    style={{width: 80}}
                                    value={filter.overallSocialRating}
                                    onChange={(value) => handleFilterChange({overallSocialRating: value})}
                                >
                                    {Object.values(FILTER_OPTIONS.OVERALL_SOCIAL_RATING).map(({
                                                                                                  value,
                                                                                                  label,
                                                                                                  description
                                                                                              }: PreferenceOption) => (
                                        <Select.Option key={value} value={value}>
                                            <div className="select-option-content">
                                                {description ? (
                                                    <Tooltip title={description} placement="right">
                                                        <div>{label}</div>
                                                    </Tooltip>
                                                ) : (
                                                    <div>{label}</div>
                                                )}
                                            </div>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Space>
                        </div>
                    ),
                    key: 'overallSocialRating',
                    width: 100,
                    render: (_, record) => (
                        <Space direction="vertical">
                            <Tag
                                color={getTagColor(record.overallSocialRating, 'OVERALL_SOCIAL_RATING')}>능력 {record.overallSocialRating}</Tag>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>학력</div>
                            <Space>
                                <Select
                                    placeholder="학력"
                                    size="small"
                                    style={{width: 80}}
                                    value={filter.univRating}
                                    onChange={(value) => handleFilterChange({univRating: value})}
                                >
                                    {Object.values(FILTER_OPTIONS.UNIV_RATING).map(({
                                                                                        value,
                                                                                        label,
                                                                                        description
                                                                                    }: PreferenceOption) => (
                                        <Select.Option key={value} value={value}>
                                            <div className="select-option-content">
                                                {description ? (
                                                    <Tooltip title={description} placement="right">
                                                        <div>{label}</div>
                                                    </Tooltip>
                                                ) : (
                                                    <div>{label}</div>
                                                )}
                                            </div>
                                        </Select.Option>
                                    ))}
                                </Select>
                            </Space>
                        </div>
                    ),
                    key: 'univRating',
                    width: 100,
                    render: (_, record) => (
                        <Space direction="vertical">
                            <Tag color={getTagColor(record.univRating, 'UNIV_RATING')}>학력 {record.univRating}</Tag>
                        </Space>
                    )
                }
            ]
        },
        {
            title: '직업/학력 정보',
            align: 'center',
            children: [{
                title: () => (
                    <div>
                        <div>직업</div>
                    </div>
                ),
                key: 'career',
                width: 180,
                align: 'center',
                render: (_, record) => (
                    <Space direction="vertical">
                        <div>{record.careerDetail}</div>
                        <div>{record.careerSubGroup}</div>
                    </Space>
                )
            }, {
                title: () => (
                    <div>
                        <div>대학</div>
                    </div>
                ),
                key: 'career',
                width: 180,
                align: 'center',
                render: (_, record) => (
                    <Space direction="vertical">
                        <div>{record.univName !== "" ? record.univName : "-"}</div>
                    </Space>
                )
            },
                {
                    title: () => (
                        <div>
                            <div>연봉</div>
                        </div>
                    ),
                    key: 'career',
                    width: 100,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div>{record.income != null ? formatKoreanMoney(record.income) : "-"}</div>
                        </Space>
                    )
                }
            ]
        },
        {
            align: 'center',
            children: [
                {
                    title: () => (
                        <div>
                            <div>성향 정보</div>
                        </div>
                    ),
                    key: 'preference',
                    width: 180,
                    align: 'center',
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>종교</b>
                                </div>
                                {record.religion ?? "-"}
                            </div>
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>흡연</b>
                                </div>
                                {record.smokingStatus}
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{width: '40px'}}>
                                    <b>음주</b>
                                </div>
                                {record.drinkingStatus}
                            </div>
                        </Space>
                    )
                },
                {
                    title: () => (
                        <div>
                            <div>이상형 정보</div>
                            <Select
                                placeholder="유저 타입"
                                size="small"
                                style={{width: 120}}
                                value={filter.preferenceType}
                                onChange={(value) => handleFilterChange({preferenceType: value})}
                            >
                                {Object.values(FILTER_OPTIONS.PREFERENCE_TYPE).map(({
                                                                                        value,
                                                                                        label,
                                                                                        description
                                                                                    }: PreferenceOption) => (
                                    <Select.Option key={value} value={value}>
                                        <div className="select-option-content">
                                            {description ? (
                                                <Tooltip title={description} placement="right">
                                                    <div>{label}</div>
                                                </Tooltip>
                                            ) : (
                                                <div>{label}</div>
                                            )}
                                        </div>
                                    </Select.Option>
                                ))}
                            </Select>
                        </div>
                    ),
                    key: 'preferenceType',
                    align: 'center',
                    width: 180,
                    render: (_, record) => (
                        <Space direction="vertical">
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>타입</b>
                                </div>
                                <Tag
                                    color={getTagColor(record.preferenceType, 'PREFERENCE_TYPE')}>{record.preferenceType}</Tag>
                            </div>
                            <div style={{display: 'flex', marginBottom: '4px'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>나이</b>
                                </div>
                                {record.age}
                            </div>
                            <div style={{display: 'flex'}}>
                                <div style={{width: '40px', marginRight: '8px'}}>
                                    <b>키</b>
                                </div>
                                {record.height}
                            </div>
                        </Space>
                    )
                },
            ]
        },
        {
            title: "추천 정보",
            children: [{
                title: () => (
                    <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                        <div>미끼 추천</div>
                        <div style={{fontSize: '12px', color: '#808080'}}>추천 수 / 가능 수</div>
                    </div>
                ),
                key: 'upperLevelRecommendation',
                width: 180,
                align: 'center',
                render: (_, record) => {
                    // 추천 정보를 레벨별로 그룹화
                    const grouped = record.receivedRecommendations.reduce<GroupedRecommendations>((acc, curr) => {
                        const level = curr.recommendationLevel;
                        acc[level] = acc[level] || [];
                        acc[level].push(curr);
                        return acc;
                    }, {});

                    return (
                        <Space direction="horizontal">
                            <b>{grouped['미끼 추천']?.length || 0}</b> / 0~3
                        </Space>
                    )
                }
            },
                {
                    title: () => (
                        <div style={{display: 'flex', flexDirection: 'column', gap: '4px'}}>
                            <div>동급 추천</div>
                            <div style={{fontSize: '12px', color: '#808080'}}>추천 수 / 가능 수</div>
                        </div>
                    ),
                    key: 'sameLevelRecommendation',
                    width: 180,
                    align: 'center',
                    render: (_, record) => {
                        // 추천 정보를 레벨별로 그룹화
                        const grouped = record.receivedRecommendations.reduce<GroupedRecommendations>((acc, curr) => {
                            const level = curr.recommendationLevel;
                            acc[level] = acc[level] || [];
                            acc[level].push(curr);
                            return acc;
                        }, {});

                        return (
                            <Space direction="horizontal">
                                <b>{grouped['동급 추천']?.length || 0}</b> / 5~8
                            </Space>
                        )
                    }
                }]
        }
    ];

    type TableColumns = NonNullable<TableProps['columns']>;

    const calculateColumnsTotalWidth = (columns: TableColumns): number => {
        const getColumnsWidth = (cols: TableColumns): number => {
            return cols.reduce((total: number, column) => {
                // title이 객체이고 children이 있는 경우 (그룹 컬럼)
                if (typeof column === 'object' && 'children' in column) {
                    return total + getColumnsWidth(column.children);
                }
                // width가 없는 경우 기본값 100px 적용
                return total + (Number(column.width) || 100);
            }, 0);
        };

        return getColumnsWidth(columns);
    };


    return (
        <MatchLayout>
            <StyledCard
                title={
                    <Space
                        size="middle"
                        style={{width: '100%', display: 'flex', justifyContent: 'space-between'}}>
                        <Space size="small" style={{display: 'flex', alignItems: 'center'}}>
                            <span style={{fontSize: '22px'}}>추천 받은 유저 목록</span>
                            <Tag color="blue" style={{margin: 0, fontSize: '14px'}}>
                                결과 : {recommendationUserQueryResult?.pagination.totalElements || 0}명
                            </Tag>
                        </Space>
                        <RecommendationGlobalTableFilter filter={filter}
                                                         onFilterChange={handleFilterChange}
                                                         onReset={handleReset}/>
                    </Space>
                }
            >
                <Table
                    rowKey={(record) => record.id}
                    onRow={(record) => ({
                        onClick: () => handleRowClick(record), // 클릭 이벤트
                        style: {cursor: 'pointer'} // 커서 스타일
                    })}
                    columns={columns}
                    dataSource={recommendationUserQueryResult?.contents || []}
                    scroll={{
                        x: calculateColumnsTotalWidth(columns),
                        scrollToFirstRowOnChange: true
                    }}
                    pagination={{
                        current: filter.page + 1,
                        pageSize: filter.pageSize,
                        total: recommendationUserQueryResult?.pagination.totalElements || 0,
                        onChange: (page, pageSize) => handlePageChange(page, pageSize),
                        showSizeChanger: true,
                        showQuickJumper: true,
                        style: {margin: '16px 0'} // 페이지네이션 여백 추가
                    }}
                    bordered={true}
                    style={{
                        '--ant-table-border-color': '#f0f0f0'  // 테두리 색상 변경
                    } as React.CSSProperties}
                />
            </StyledCard>
        </MatchLayout>
    );
};

const StyledCard = styled(Card)`
    width: 100%;
`;

export default RecommendationHome;

