import apiClient from "./apiClient";

export interface MatchingUser {
  userProfileId: number;
  userId: number;
  name: string;
  careerType: string;
  careerDetail: string;
  careerSubGroup: string;
  finalAcademic: string;
  univName: string;
  age: number;
  height: number;
  address: string;
  addressSubGroup: string;
  income: number;
  religion: string;
  partnerUnderAge: string;
  partnerUpperAge: string;
  idOkPartnerSameAge: string;
  partnerHeightRange: string[];
  smokingStatus: string;
  drinkingStatus: string;
  gender: string;
  userImages: string[];
  appealPoint: string;
  referencePoint: string[];
  userRequest: string;
  careerRating: string;
  univRating: string;
  appearanceRating: string;
  incomeRating: string;
  idealType: string;
  refereeName: string;
  funnel: string;
  hasMatching?: boolean;
  phoneNumber: string;
}

export interface GetMatchingUserParameter {
  gender: string;
  name?: string;
  minAge?: number;
  maxAge?: number;
  minHeight?: number;
  maxHeight?: number;
  appearanceRating?: string;
  careerRating?: string;
  smokingStatus?: string;
  drinkingStatus?: string;
  address?: string;
  addressSubGroup?: string;
  refereeName?: string;
  hasMatching?: boolean;
}

export const getMatchingUsers = async (params: GetMatchingUserParameter) => {
  const filteredParams = Object.entries(params)
    .filter(
      ([_, value]) =>
        value !== undefined && value !== null && value !== "" && value !== 0
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const res = await apiClient.get<MatchingUser[]>(`/v1/admin/matching/users`, {
    params: filteredParams,
  });
  return res.data;
};

export const getMatchingUserByProfileId = async (parameter: {
  profileId: number;
}) => {
  const res = await apiClient.get<MatchingUser>(
    `/v1/admin/matching/users/${parameter.profileId}`
  );
  return res.data;
};

export interface GetMatchingUserPriority {
  userProfileId: number;
  userId: number;
  name: string;
  careerType: string;
  careerDetail: string;
  careerSubGroup: string;
  finalAcademic: string;
  univName: string;
  age: number;
  height: number;
  address: string;
  addressSubGroup: string;
  income: number;
  religion: string;
  partnerUnderAge: string;
  partnerUpperAge: string;
  idOkPartnerSameAge: string;
  partnerHeightRange: string[];
  smokingStatus: string;
  drinkingStatus: string;
  gender: string;
  userImages: string[];
  appealPoint: string;
  referencePoint: string[];
  userRequest: string;
  careerRating: string;
  univRating: string;
  appearanceRating: string;
  incomeRating: string;
  idealType: string;
  refereeName: string;
  funnel: string;
  choiceRanking: string;
  refundUser: boolean;
  partnerChoiceRanking: string;
  bothRecommendation: boolean;
  essentialMatchingPossible: boolean;
  phoneNumber: string;
}

// 두번째칸 유저 우선순위
export const getMatchingUserPriority = async (profileId: number) => {
  const res = await apiClient.get<GetMatchingUserPriority[]>(
    `/v1/admin/${profileId}/matching/priority`
  );
  return res.data;
};

export interface GetMatchedUserPriority {
  userProfileId: number;
  userId: number;
  name: string;
  careerType: string;
  careerDetail: string;
  careerSubGroup: string;
  finalAcademic: string;
  univName: string;
  age: number;
  height: number;
  address: string;
  addressSubGroup: string;
  income: number;
  religion: string;
  partnerUnderAge: string;
  partnerUpperAge: string;
  idOkPartnerSameAge: string;
  partnerHeightRange: string[];
  smokingStatus: string;
  drinkingStatus: string;
  gender: string;
  userImages: string[];
  appealPoint: string;
  referencePoint: string[];
  userRequest: string;
  careerRating: string;
  univRating: string;
  appearanceRating: string;
  incomeRating: string;
  idealType: string;
  refereeName: string;
  funnel: string;
  refundUser: boolean;
  choiceRanking: string;
  mbti: string;
  partnerChoiceRanking: string;
  bothRecommendation: boolean;
  phoneNumber: string;
}

// 우선순위 유저의 우선순위
export const getMatchedUserPriority = async (profileId: number) => {
  const res = await apiClient.get<GetMatchedUserPriority[]>(
    `/v1/admin/${profileId}/matched/priority`
  );
  return res.data;
};
interface PostMatchingBody {
  maleUserId: number;
  femaleUserId: number;
}

export const postMatching = async (body: PostMatchingBody) => {
  await apiClient.post(`/v1/admin/matching`, body);
};

interface PostFreeAdditionalRecommendationBody {
  userId: number;
  partnerUserId: number;
}

export const postTmpMatching = async (body: PostMatchingBody) => {
  await apiClient.post(`/v1/admin/tmp-matching`, body);
};
export const postFreeAdditionalRecommendation = async (
  body: PostFreeAdditionalRecommendationBody
) => {
  await apiClient.post(`/v1/admin/additional-recommendation/both/free`, body);
};

export interface GetUserMatchedPartner {
  userProfileId: number;
  userId: number;
  name: string;
  careerType: string;
  careerDetail: string;
  careerSubGroup: string;
  finalAcademic: string;
  univName: string;
  age: number;
  height: number;
  address: string;
  addressSubGroup: string;
  income: number;
  religion: string;
  partnerUnderAge: string;
  partnerUpperAge: string;
  idOkPartnerSameAge: string;
  partnerHeightRange: string[];
  smokingStatus: string;
  drinkingStatus: string;
  gender: string;
  userImages: string[];
  appealPoint: string;
  referencePoint: string[];
  userRequest: string;
  careerRating: string;
  univRating: string;
  appearanceRating: string;
  incomeRating: string;
  idealType: string;
  refereeName: string;
  funnel: string;
  matchingType: string;
}

export const getUserMatchedPartners = async (userId: number) => {
  const res = await apiClient.get<GetUserMatchedPartner[]>(
    `/v1/admin/matching/users/${userId}/matches`
  );
  return res.data;
};

export interface MatchingFilter {
  gender: string;
  name: string;
  minAge: number;
  maxAge: number;
  minHeight: number;
  maxHeight: number;
  appearanceRating: string;
  careerRating: string;
  smokingStatus: string;
  drinkingStatus: string;
  religion : string;
  address: string;
  addressSubGroup: string;
  refereeName: string;
  recommendationCount: number;
  hasNotMatching: boolean;
}

export interface GetAdditionalMatchingNomineeParameter {
  userId: number;
  name?: string;
  minAge?: number;
  maxAge?: number;
  minHeight?: number;
  maxHeight?: number;
  appearanceRating?: string;
  careerRating?: string;
  smokingStatus?: string;
  drinkingStatus?: string;
  address?: string;
  addressSubGroup?: string;
  refereeName?: string;
  hasMatching?: boolean;
}

// 추가로 매칭해줄 후보 유저들 get api
export const getAdditionalMatchingNominee = async (
  params: GetAdditionalMatchingNomineeParameter
) => {
  const filteredParams = Object.entries(params)
    .filter(
      ([_, value]) =>
        value !== undefined && value !== null && value !== "" && value !== 0
    )
    .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

  const res = await apiClient.get<GetAdditionalMatchingNominee[]>(
    `/v1/admin/matching/users/${params.userId}/add-matches`,
    {
      params: filteredParams,
    }
  );
  return res.data;
};

export interface GetAdditionalMatchingNominee {
  userProfileId: number;
  userId: number;
  name: string;
  careerType: string;
  careerDetail: string;
  careerSubGroup: string;
  finalAcademic: string;
  univName: string;
  age: number;
  height: number;
  address: string;
  addressSubGroup: string;
  income: number;
  religion: string;
  partnerUnderAge: string;
  partnerUpperAge: string;
  idOkPartnerSameAge: string;
  partnerHeightRange: string[];
  smokingStatus: string;
  drinkingStatus: string;
  gender: string;
  userImages: string[];
  appealPoint: string;
  referencePoint: string[];
  userRequest: string;
  careerRating: string;
  univRating: string;
  appearanceRating: string;
  incomeRating: string;
  idealType: string;
  refereeName: string;
  funnel: string;
  mbti: string;
  refundUser: boolean;
  recommendedToNowUser: boolean;
  phoneNumber: string;
}
