import { Button, Descriptions, Flex } from "antd";
import styled from "@emotion/styled";
import UserImageCarousel from "../../user/components/UserImageCarousel";
import MatchingViewBtn from "./MatchingViewBtn";
import { GetMatchingUserPriority } from "../../../utils/api/matching";

interface RecommendedProfileCardProps {
  partner: GetMatchingUserPriority; // 현재 조회하고 있는 유저에게 추천해준 유저
  onCardClick: () => void;
  onMatching: () => void;
  onTmpMatching: () => void;
  hasMatching: boolean;
}

const PriorityProfileCard = ({
  partner,
  onCardClick,
  onMatching,
  onTmpMatching,
  hasMatching,
}: RecommendedProfileCardProps) => {
  return (
    <Section
      style={{
        backgroundColor: partner.refundUser ? "#f0f0f0" : undefined, // 연한 회색 배경
        color: partner.refundUser ? "#d9d9d9" : undefined, // 연한 글씨 색상
        opacity: partner.refundUser ? "0.5" : "1", // 투명도 조정
      }}
    >
      <FlexColumn
        style={{
          backgroundColor: "#f9f9f9",
          cursor: "pointer",
        }}
      >
        <Flex>
          {partner.bothRecommendation ? (
            <TwoWayRecommendedBadge>양방향 추천</TwoWayRecommendedBadge>
          ) : (
            <OneWayRecommendedBadge>일방향 추천</OneWayRecommendedBadge>
          )}
          <PriorityChoiceBadge>{partner.choiceRanking}</PriorityChoiceBadge>
        </Flex>
        <ProfileImages>
          <UserImageCarousel images={partner.userImages} />
        </ProfileImages>

        <Flex
          style={{
            paddingTop: "32px",
            alignItems: "flex-start",
          }}
        >
          {<MatchingViewBtn userName={partner.name} userId={partner.userId} />}
        </Flex>

        <div
          onClick={() => {
            onCardClick();
          }}
          style={{
            width: 456,
          }}
        >
          <Descriptions column={2} title="유저 요약 정보">
            <Descriptions.Item label="이름">{partner.name}</Descriptions.Item>
            <Descriptions.Item label="나이">{partner.age}</Descriptions.Item>
            <Descriptions.Item label="키">{partner.height}</Descriptions.Item>
            <Descriptions.Item label="전화번호">
              {partner.phoneNumber}
            </Descriptions.Item>
            <Descriptions.Item label="직장">
              {partner.careerDetail}
            </Descriptions.Item>
            <Descriptions.Item label="외모 등급">
              {partner.appearanceRating}
            </Descriptions.Item>
            <Descriptions.Item label="능력 등급">
              {partner.careerRating}
            </Descriptions.Item>
            <Descriptions.Item label="종교">
              {partner.religion}
            </Descriptions.Item>
            <Descriptions.Item label="도시">
              {partner.address}
            </Descriptions.Item>
            <Descriptions.Item label="거주지">
              {partner.addressSubGroup}
            </Descriptions.Item>
            <Descriptions.Item label="흡연 / 음주 여부">
              {partner.smokingStatus} / {partner.drinkingStatus}
            </Descriptions.Item>
            <Descriptions.Item label="추천인">
              {partner.refereeName || "입력 전"}
            </Descriptions.Item>
            <Descriptions.Item label="유입 경로">
              {partner.funnel || "입력 전"}
            </Descriptions.Item>
          </Descriptions>
        </div>
      </FlexColumn>

      <Flex
        gap="large"
        vertical
        style={{
          width: "100%",
          height: "100%",
          minHeight: 517,
          alignItems: "center",
          justifyContent: "center",
          padding: "0 24px",
        }}
      >
        {!partner.refundUser &&
          partner.bothRecommendation &&
          partner.essentialMatchingPossible &&
          !hasMatching && (
            <Button
              size="large"
              type="primary"
              style={{ background: "#4caf50", width: "100%", height: 46 }}
              onClick={onMatching}
              disabled={partner.refundUser}
            >
              즉시 매칭
            </Button>
          )}

        {!partner.refundUser && (
          <Button
            size="large"
            type="primary"
            style={{ background: "#007bff", width: "100%", height: 46 }}
            onClick={onTmpMatching}
            disabled={partner.refundUser}
          >
            추천 없이 바로 추가 매칭
          </Button>
        )}

        {/* <Button
          type="primary"
          style={{ background: " #fd7e14", width: "45%", height: 46 }}
        >
          {" "}
          추가 매칭 (무료)
        </Button>
        <Button type="primary" danger style={{ width: "45%", height: 46 }}>
          추가 카드 발송
        </Button> */}
      </Flex>
    </Section>
  );
};

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  height: 100%;
  align-items: center;
  justify-content: flex-start;
  align-items: flex-start;
  padding-right: 60px;
  border-right: 1px solid #dbdbdb;
`;

const Section = styled.div`
  padding: 8px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  margin-bottom: 8px;
  display: flex;
  position: relative;
  height: 100%;
  align-items: flex-start;
  justify-content: space-between;
`;

const TwoWayRecommendedBadge = styled.span`
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75em;
  position: relative;
  right: 0px;
`;

const OneWayRecommendedBadge = styled.span`
  background-color: #e65272;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75em;
  position: relative;
  right: 0px;
`;

const PriorityChoiceBadge = styled.span`
  background-color: #4caf50;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.75em;
  position: relative;
  right: 0px;
  margin-left: 8px;
`;

const ProfileImages = styled.div`
  display: flex;
  overflow-x: auto;
  margin-bottom: 8px;
  margin-top: 8px;
  justify-content: center;
`;

export default PriorityProfileCard;
