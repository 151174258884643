import styled from "@emotion/styled";
import React from "react";

const Loading = () => {
  return (
    <LoadingBox>
      <LoadingImage src="/loading.gif" alt="로딩" />
    </LoadingBox>
  );
};

const LoadingBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

const LoadingImage = styled.img`
  width: 100px;
  height: 100px;
`;

export default Loading;
