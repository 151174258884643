import { putAppearance } from "../../../utils/api/user";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Button, Flex, Modal, Radio, RadioChangeEvent } from "antd";
import { CheckCircleOutlined } from "@ant-design/icons";
import styled from "@emotion/styled";
import { useState } from "react";
import UserImageCarousel from "../components/UserImageCarousel";

interface AppearanceUpdateBtnWithLabelProps {
  userId: number;
  appearanceRating: string;
  userImages: string[];
  userName: string;
  openNotificationWithIcon: (name: string, appearanceRating: any) => void;
}

const AppearanceUpdateBtnWithLabel = ({
  userId,
  appearanceRating,
  userImages,
  userName,
  openNotificationWithIcon,
}: AppearanceUpdateBtnWithLabelProps) => {
  const qc = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: () =>
    putAppearance({
        userId,
        appearanceRating: appearanceLevel ?? "FIFTH_GRADE"
      }),
    onSuccess: () => {
      const updatedAppearanceRating = convertGradeToKorean(appearanceLevel);
      openNotificationWithIcon(userName, updatedAppearanceRating);
      qc.invalidateQueries({ queryKey: ["users"] });
      handleCancel();
    },
  });

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [appearanceLevel, setAppearanceLevel] = useState(null);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const onChangeAppearanceLevel = (e: RadioChangeEvent) => {
    setAppearanceLevel(e.target.value);
  };

  const convertGradeToKorean = (grade: string | null) => {
    switch (grade) {
      case null:
        return "";
      case "FIRST_GRADE":
        return "상";
      case "SECOND_GRADE":
        return "중상";
      case "THIRD_GRADE":
        return "중";
      case "FOURTH_GRADE":
        return "중하";
      case "FIFTH_GRADE":
        return "하";
      case "UN_RANK":
        return "등급 제외";
      default:
        return "상";
    }
  };

  return (
    <FlexColumn>
      {appearanceRating}
      <Button type="primary" onClick={showModal}>
        유저 외모 등급 수정
      </Button>
      <Modal
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        okText="닫기"
        width={1248}
        title="유저 외모 등급 수정"
        footer={[
          <Button key="submit" type="primary" onClick={handleOk}>
            닫기
          </Button>,
        ]}
      >
        <Flex>
          <UserImageCarousel images={userImages} />
          <FlexColumn>
            <Radio.Group
              size="large"
              buttonStyle="solid"
              onChange={onChangeAppearanceLevel}
              value={appearanceLevel}
            >
              <Radio.Button
                style={{
                  width: 80,
                  textAlign: "center",
                }}
                value={"FIRST_GRADE"}
              >
                상
              </Radio.Button>
              <Radio.Button
                style={{
                  width: 80,
                  textAlign: "center",
                }}
                value={"SECOND_GRADE"}
              >
                중상
              </Radio.Button>
              <Radio.Button
                style={{
                  width: 80,
                  textAlign: "center",
                }}
                value={"THIRD_GRADE"}
              >
                중
              </Radio.Button>{" "}
              <Radio.Button
                style={{
                  width: 80,
                  textAlign: "center",
                }}
                value={"FOURTH_GRADE"}
              >
                중하
              </Radio.Button>
              <Radio.Button
                style={{
                  width: 80,
                  textAlign: "center",
                }}
                value={"FIFTH_GRADE"}
              >
                하
              </Radio.Button>
              <Radio.Button
                style={{
                  width: 100,
                  textAlign: "center",
                }}
                value={"UN_RANK"}
              >
                등급 제외
              </Radio.Button>
            </Radio.Group>
            <SubmitContainer>
              <Button
                type="primary"
                icon={<CheckCircleOutlined />}
                disabled={appearanceLevel === null}
                size="large"
                onClick={() => mutate()}
              >
                <b>{convertGradeToKorean(appearanceLevel)}</b>(으)로 외모 등급
                수정
              </Button>
            </SubmitContainer>
          </FlexColumn>
        </Flex>
      </Modal>
    </FlexColumn>
  );
};

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
`;

const SubmitContainer = styled.div`
  display: flex;
  padding-top: 20px;
`;

export default AppearanceUpdateBtnWithLabel;
