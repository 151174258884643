import axios from "axios";
import userStorage from "../userStorage";

export const nonUserApiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
});

apiClient.interceptors.request.use(
  (config) => {
    if (!config?.headers) {
      throw new Error(
        `Expected 'config' and 'config.headers' not to be undefined`
      );
    }

    const tokens = userStorage.get();
    if (!tokens) return config;
    const { accessToken } = tokens;

    config.headers.Authorization = `Bearer ${accessToken}`;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

apiClient.interceptors.response.use(
  (response) => response,

  async (error) => {
    console.log(error);
    const {
      response: { status },
    } = error;
    if (!status) {
      throw new Error("Unknown Error");
    }
    switch (status) {
      case 409:
        throw new Error(error.response.data.message);

      case 500:
        throw new Error(error.response.data.message);

      default:
        throw new Error("Unknown Error");
    }
  }
);

export default apiClient;
