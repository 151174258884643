import apiClient from "./apiClient";

export interface User {
  userId: number;
  userProfileId: number;
  gender: string;
  age: string;
  name: string;
  userImages: string[];
  appearanceRating: string;
  careerType: string;
  careerDetail: string;
  careerRating: string;
  univName: string;
  univRating: string;
  phoneNumber: string;
  height: string;
  income: number;
  address: string;
  religion: string;
  isOkToMeetSameAge: string;
  partnerUnderAge: string;
  partnerUpperAge: string;
  partnerHeightRange: string[];
  appealPoint: string;
  referencePoint: string[];
  kakaoNickname: string;
  kakaoProfileImage: string;
  kakaoEmail: string;
  kakaoAppUserId: string;
  registrationStep: string;
  refundUser?: boolean;
}

interface getUsersParameter {
  gender : string
  name?: string;
  minAge?: number;
  maxAge?: number;
  minHeight?: number;
  maxHeight?: number;
  appearanceRating?: string;
  careerRating?: string;
  smokingStatus?: string;
  drinkingStatus?: string;
  address?: string;
  addressSubGroup?: string;
  refereeName?: string;
  orderBy?: string;
}

export const getUsers = async (params: getUsersParameter) => {
  const filteredParams = Object.entries(params)
  .filter(
    ([_, value]) =>
      value !== undefined && value !== null && value !== "" && value !== 0
  )
  .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});
  const res = await apiClient.get<User[]>(`/v1/admin/users`, {
    params: filteredParams,
  });
  return res.data;
};

export interface UnivInjection {
  univInspectionId: number;
  univName: string;
  userGender: string;
  status: string;
  proofImages: string[];
  univCertificationStatus: string;
  userProfileData: {
    userProfileId: number;
    userProfileName: string;
  };
}

export const getUnivInspection = async () => {
  const res = await apiClient.get<UnivInjection[]>(`/v1/admin/univ-inspection`);
  return res.data;
};

export type ExposureStatus = "BEFORE_SET" | "VISIBLE" | "HIDDEN";

interface PutUnivInspection {
  univInspectionId: number;
  univRating: Rating;
  univNameExposureStatus: ExposureStatus;
}
export const putUnivInspection = async (body: PutUnivInspection) => {
  await apiClient.put(`/v1/admin/univ-inspection`, body);
};

export interface CareerInspection {
  careerInspectionId: number;
  careerGroupName: string;
  careerSubGroupName: string;
  careerDetailName: string;
  userGender: string;
  status: string;
  proofImages: string[];
  age: number;
  careerCertificationStatus: string;
  userProfileData: {
    userProfileId: number;
    userProfileName: string;
  };
}

export const getCareerInspection = async () => {
  const res = await apiClient.get<CareerInspection[]>(
    `/v1/admin/career-inspection`
  );
  return res.data;
};

export type Rating =
  | "FIRST_GRADE"
  | "SECOND_GRADE"
  | "THIRD_GRADE"
  | "FOURTH_GRADE"
  | "FIFTH_GRADE"
  | "UN_RANK";

interface PutCareerInspection {
  careerInspectionId: number;
  careerRating: Rating;
}
export const putCareerInspection = async (body: PutCareerInspection) => {
  await apiClient.put(`/v1/admin/career-inspection`, body);
};

interface Appearance {
  userId: number;
  name: string;
  gender: string;
  age: number;
  height: string;
  userImages: string[];
}

export const getAppearance = async () => {
  const res = await apiClient.get<Appearance[]>(`/v1/admin/appearance`);
  return res.data;
};



interface PutAppearance {
  userId: number;
  appearanceRating: Rating;
}

export const putAppearance = async (body: PutAppearance) => {
  await apiClient.put(`/v1/admin/appearance`, body);
};

interface PutAppearanceWithImages {
  userId: number;
  appearanceRating: Rating;
  representativeImages: string[];
}

export const putAppearanceWithImages = async (body: PutAppearanceWithImages) => {
  await apiClient.put(`/v1/admin/appearance/representative-images`, body);
};

interface PutUserRepresentativeImages {
  userProfileId: number;
  representativeImages: string[];
}

export const putUserRepresentativeImages = async (body: PutUserRepresentativeImages) => {
  await apiClient.put(`/v1/admin/user/representative-images`, body);
};


interface UserFilter {
  appearanceRatings: string[];
  careerRatings: string[];
  smokingStatuses: string[];
  drinkingStatuses: string[];
  religions: string[];
  addressGroupMap: {
    서울: {
      address: string;
      addressSubGroups: string[];
    };
    수도권: {
      address: string;
      addressSubGroups: string[];
    };
    비수도권: {
      address: string;
      addressSubGroups: string[];
    };
  };
  refereeNames: string[];
  orderFilterNameMap: {
    MBTI: string;
    나이: string;
    결제시간: string;
    대학명: string;
    이름: string;
    직업명: string;
    아이디: string;
    키: string;
  }
  orderMenu: {
    오름차순: string;
    내림차순: string;
  };
}


export const getUserFilterCategory = async () => {
  const res = await apiClient.get<UserFilter>(
    `/v1/admin/users-filter-category`
  );
  return res.data;
};