import styled from "@emotion/styled";
import {
  Button,
  Card,
  Descriptions,
  Flex,
  Image,
  Radio,
  RadioChangeEvent,
  Typography,
} from "antd";
import { useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { putAppearanceWithImages } from "../../../utils/api/user";

interface Props {
  userImages: string[];
  userId: number;
  gender: string;
  name: string;
  openNotificationWithIcon: (name: string) => void;
  onChangeLoading: (loading: boolean) => void;
  age: number;
  height: string;
}

const AppearanceCard = ({
  name,
  userImages,
  userId,
  gender,
  openNotificationWithIcon,
  onChangeLoading,
  age,
  height,
}: Props) => {
  const [appearanceLevel, setAppearanceLevel] = useState(null);
  const [representativeImages, setRepresentativeImages] = useState<string[]>(
    []
  );

  const onAddRepresentativeImage = (image: string) => {
    // 중복 추가 방지
    if (representativeImages.includes(image)) return;

    setRepresentativeImages((prev) => [...prev, image]);
  };

  const onRemoveRepresentativeImage = (image: string) => {
    setRepresentativeImages((prev) =>
      prev.filter((prevImage) => prevImage !== image)
    );
  };

  const qc = useQueryClient();
  const { mutate, status } = useMutation({
    mutationFn: () =>
      putAppearanceWithImages({
        userId,
        appearanceRating: appearanceLevel ?? "FIFTH_GRADE",
        representativeImages,
      }),
    onSuccess: () => {
      openNotificationWithIcon(name);
      qc.invalidateQueries({ queryKey: ["appearance"] });
      setTimeout(() => {
        onChangeLoading(false);
      }, 300);
    },
    onError: () => {
      setTimeout(() => {
        onChangeLoading(false);
      }, 300);
    },
    onMutate: () => {
      onChangeLoading(true);
    },
  });

  const onChangeAppearanceLevel = (e: RadioChangeEvent) => {
    setAppearanceLevel(e.target.value);
  };

  const convertGradeToKorean = (grade: string | null) => {
    switch (grade) {
      case null:
        return "";
      case "FIRST_GRADE":
        return "상";
      case "SECOND_GRADE":
        return "중상";
      case "THIRD_GRADE":
        return "중";
      case "FOURTH_GRADE":
        return "중하";
      case "FIFTH_GRADE":
        return "하";
      case "UN_RANK":
        return "등급 제외";
      default:
        return "상";
    }
  };
  if (status === "pending") return <div></div>;

  return (
    <Card style={{ width: 1440 }}>
      <CardImageBox>
        {userImages.map((src, index) => {
          return (
            <div key={`representative_image_${index}`}>
              <Image
                style={{
                  width: 400,
                }}
                width={400}
                alt={`image_${index}`}
                src={src}
              />
              <Flex
                justify="center"
                gap="small"
                style={{
                  paddingTop: 12,
                }}
              >
                <Button
                  type="primary"
                  onClick={() => onAddRepresentativeImage(src)}
                >
                  대표 이미지에 추가
                </Button>
              </Flex>
            </div>
          );
        })}
      </CardImageBox>

      <Typography.Title level={5}>대표 이미지 리스트</Typography.Title>
      <Flex
        style={{
          paddingBottom: 24,
          gap: 12,
        }}
      >
        {representativeImages.length > 0 ? (
          representativeImages.map((src, index) => {
            return (
              <div
                key={`selected_image_${index}`}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 12,
                }}
              >
                <Image
                  style={{
                    width: 150,
                    height: 150,
                  }}
                  width={150}
                  alt={`image_${index}`}
                  src={src}
                />
                <Button
                  type="primary"
                  danger
                  onClick={() => onRemoveRepresentativeImage(src)}
                >
                  대표 이미지에서 제외
                </Button>
              </div>
            );
          })
        ) : (
          <Flex
            justify="center"
            align="center"
            style={{
              height: 150,
              backgroundColor: "#f0f0f0",
            }}
          >
            대표 이미지가 없습니다.
          </Flex>
        )}
      </Flex>
      <Descriptions column={1}>
        <Descriptions.Item label="유저 id">{userId}</Descriptions.Item>
        <Descriptions.Item label="이름">{name}</Descriptions.Item>
        <Descriptions.Item label="성별">{gender}</Descriptions.Item>
        <Descriptions.Item label="나이">{age}</Descriptions.Item>
        <Descriptions.Item label="키">{height}</Descriptions.Item>
      </Descriptions>
      <Radio.Group
        size="large"
        buttonStyle="solid"
        onChange={onChangeAppearanceLevel}
        value={appearanceLevel}
      >
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"FIRST_GRADE"}
        >
          상
        </Radio.Button>
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"SECOND_GRADE"}
        >
          중상
        </Radio.Button>
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"THIRD_GRADE"}
        >
          중
        </Radio.Button>{" "}
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"FOURTH_GRADE"}
        >
          중하
        </Radio.Button>
        <Radio.Button
          style={{
            width: 80,
            textAlign: "center",
          }}
          value={"FIFTH_GRADE"}
        >
          하
        </Radio.Button>
        <Radio.Button
          style={{
            width: 100,
            textAlign: "center",
          }}
          value={"UN_RANK"}
        >
          등급 제외
        </Radio.Button>
      </Radio.Group>
      <SubmitContainer>
        <Button
          type="primary"
          icon={<CheckCircleOutlined />}
          disabled={
            appearanceLevel === null || representativeImages.length === 0
          }
          size="large"
          onClick={() => mutate()}
        >
          <b>{convertGradeToKorean(appearanceLevel)}</b>(으)로 분류
        </Button>
      </SubmitContainer>
    </Card>
  );
};
const CardImageBox = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll;
  padding-bottom: 20px;
  gap: 12px;
`;

const SubmitContainer = styled.div`
  display: flex;
  padding-top: 20px;
`;

export default AppearanceCard;
