import styled from "@emotion/styled";
import { Input, InputNumber, Select, Typography } from "antd";
import { RecommendationPartnerFilter } from "../../../utils/api/recommend";
import { MatchingFilter } from "../../../utils/api/matching";

interface AdditionalRecommendationFilterProps {
  filter: MatchingFilter;
  onChangeFilter: (value: string | number | boolean, key: string) => void;
  setFilter: React.Dispatch<React.SetStateAction<MatchingFilter>>;
  recommendedPartnerFilter: RecommendationPartnerFilter;
}

const AdditionalRecommendationFilter = ({
  filter,
  setFilter,
  onChangeFilter,
  recommendedPartnerFilter,
}: AdditionalRecommendationFilterProps) => {
  const { Search } = Input;

  return (
    <>
      <div>
        <div
          style={{
            marginBottom: 12,
            width: "500px",
            margin: "0 auto",
          }}
        >
          <Search
            placeholder="유저명을 검색해주세요"
            allowClear
            enterButton="검색"
            size="large"
            onChange={(e) => onChangeFilter(e.target.value, "name")}
            value={filter.name}
          />
        </div>
        <FilterBox>
          <FlexColumn>
            <Flex
              style={{
                padding: "16px",
                margin: "0 auto",
              }}
            >
              <div>
                <Typography.Title level={5}>외모 등급</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="외모 레벨"
                  style={{ width: 150 }}
                  value={filter.appearanceRating}
                  onChange={(value) =>
                    onChangeFilter(value, "appearanceRating")
                  }
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.appearanceRatings.map(
                      (data) => ({
                        value: data,
                        label: data,
                      })
                    ),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>능력 등급</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="능력 등급"
                  style={{ width: 150 }}
                  value={filter.careerRating}
                  onChange={(value) => onChangeFilter(value, "careerRating")}
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.careerRatings.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>지역 (시,도)</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="지역 (시,도)"
                  style={{ width: 150 }}
                  value={filter.address}
                  onChange={(value) => onChangeFilter(value, "address")}
                  options={[
                    { value: "", label: "전체" },
                    { value: "서울", label: "서울" },
                    { value: "수도권", label: "수도권" },
                    { value: "비수도권", label: "비수도권" },
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>지역 (시,군,구)</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="지역 (시,군,구)"
                  style={{ width: 150 }}
                  disabled={!filter.address}
                  value={filter.addressSubGroup}
                  onChange={(value) => onChangeFilter(value, "addressSubGroup")}
                  options={
                    filter.address
                      ? [
                          { value: "", label: "전체" },
                          ...recommendedPartnerFilter.addressGroupMap[
                            filter.address as "서울" | "수도권"
                          ].addressSubGroups.map((data) => ({
                            value: data,
                            label: data,
                          })),
                        ]
                      : []
                  }
                />
              </div>
              <div>
                <Typography.Title level={5}>흡연</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="흡연"
                  style={{ width: 150 }}
                  value={filter.smokingStatus}
                  onChange={(value) => onChangeFilter(value, "smokingStatus")}
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.smokingStatuses.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>음주</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="음주"
                  style={{ width: 150 }}
                  value={filter.drinkingStatus}
                  onChange={(value) => onChangeFilter(value, "drinkingStatus")}
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.drinkingStatuses.map(
                      (data) => ({
                        value: data,
                        label: data,
                      })
                    ),
                  ]}
                />
              </div>
            </Flex>
            <Flex
              style={{
                marginBottom: "36px",
                gap: "28px",
              }}
            >
              <div>
                <Typography.Title level={5}>매칭 완료 여부</Typography.Title>
                <Select
                  defaultValue="false" // 기본값을 문자열로 변경
                  style={{ width: 150 }}
                  value={String(filter.hasNotMatching)} // Boolean 값을 문자열로 변환
                  onChange={(value) =>
                    onChangeFilter(value === "true", "hasNotMatching")
                  } // 문자열을 다시 Boolean으로 변환
                  options={[
                    { value: "false", label: "전체" }, // value 값을 문자열로 변경
                    { value: "true", label: "매칭 이전" }, // value 값을 문자열로 변경
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>키 범위</Typography.Title>
                <InputNumber
                  min={130}
                  max={200}
                  value={filter.minHeight}
                  onChange={(value) =>
                    onChangeFilter(value as number, "minHeight")
                  }
                />
                cm ~{" "}
                <InputNumber
                  min={130}
                  max={200}
                  defaultValue={200}
                  value={filter.maxHeight}
                  onChange={(value) =>
                    onChangeFilter(value as number, "maxHeight")
                  }
                />
                cm
              </div>
              <div>
                <Typography.Title level={5}>나이 범위</Typography.Title>
                <InputNumber
                  min={20}
                  max={60}
                  defaultValue={20}
                  value={filter.minAge}
                  onChange={(value) =>
                    onChangeFilter(value as number, "minAge")
                  }
                />
                세 ~{" "}
                <InputNumber
                  min={20}
                  max={60}
                  defaultValue={60}
                  value={filter.maxAge}
                  onChange={(value) =>
                    onChangeFilter(value as number, "maxAge")
                  }
                />
                세
              </div>
              <div>
                <Typography.Title level={5}>추천인</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="흡연"
                  style={{ width: 150 }}
                  value={filter.refereeName}
                  onChange={(value) => onChangeFilter(value, "refereeName")}
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.refereeNames.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>종교</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="종교"
                  style={{ width: 150 }}
                  value={filter.religion}
                  onChange={(value) => onChangeFilter(value, "religion")}
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.religions.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
            </Flex>
          </FlexColumn>
        </FilterBox>
      </div>
    </>
  );
};

const Flex = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
`;

const FilterBox = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  margin: 16px 0;
  padding: 16px;
  justify-content: center;
`;

export default AdditionalRecommendationFilter;
