import { useState } from "react";
import styled from "@emotion/styled";
import { useQuery } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  Descriptions,
  List,
  Select,
  Input,
  InputNumber,
  Segmented,
  Typography,
  Button,
} from "antd";
import { getRecommendationPartnerFilterCategory } from "../../utils/api/recommend";
import { getMatchingUsers,
   MatchingUser, 
   MatchingFilter } from "../../utils/api/matching";
import MatchingLayout from "./components/MatchingLayout";
import UserImageCarousel from "../user/components/UserImageCarousel";
import MatchingViewBtn from "./components/MatchingViewBtn";

const MatchingHome = () => {
  const { Search } = Input;
  const navigate = useNavigate();

  const [filter, setFilter] = useState<MatchingFilter>({
    gender: "MALE",
    name: "",
    minAge: 19,
    maxAge: 60,
    minHeight: 130,
    maxHeight: 200,
    appearanceRating: "",
    careerRating: "",
    smokingStatus: "",
    drinkingStatus: "",
    religion: "",
    address: "",
    addressSubGroup: "",
    refereeName: "",
    recommendationCount: 12,
    hasNotMatching: false
  });

  const onChangeFilter = (value: string | number | boolean, key: string) => {
    if (key === "address") {
      setFilter((prev) => ({
        ...prev,
        [key]: value as string,
        addressSubGroup: "",
      }));
      return;
    }

    // "hasNotMatching" 키에 대한 조건문 추가 - boolean 값을 처리
  if (key === "hasNotMatching") {
    setFilter((prev) => ({
      ...prev,
      [key]: value as boolean, // "hasNotMatching"의 경우, value는 boolean으로 처리
    }));
    return;
  }
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const { data: recommendedPartnerFilter } = useQuery({
    queryKey: ["recommendationPartnerFilterCategory"],
    queryFn: () => getRecommendationPartnerFilterCategory(),
  });

  const { data: matchingUser } = useQuery({
    queryKey: ["matching-users", filter],
    queryFn: () =>
      getMatchingUsers({
        ...filter,
      }),
  });

  const onScrollTop = () => {
    window.scrollTo(0, 0);
  };

  if (!recommendedPartnerFilter) return <></>;

  return (
    <MatchingLayout>
      <Paper>
        <Typography.Title level={2}>매칭 유저 관리</Typography.Title>
        <div
          style={{
            marginBottom: 12,
            width: "500px",
          }}
        >
          <Search
            placeholder="유저명을 검색해주세요"
            allowClear
            enterButton="검색"
            size="large"
            onChange={(e) => onChangeFilter(e.target.value, "name")}
            value={filter.name}
            onSearch={(value) => navigate(`/matching?search=${value}`)}
          />
        </div>
        <FilterBox>
          <FlexColumn>
            <Flex
              style={{
                padding: "16px",
              }}
            >
              <div>
                <Typography.Title level={5}>성별</Typography.Title>
                <Segmented
                  value={filter.gender}
                  onChange={(value) => {
                    setFilter((prev) => ({
                      ...prev,
                      gender: value as "MALE" | "FEMALE",
                    }));
                  }}
                  options={[
                    {
                      label: "남자",
                      value: "MALE",
                    },
                    {
                      label: "여자",
                      value: "FEMALE",
                    },
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>외모 등급</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="외모 레벨"
                  style={{ width: 150 }}
                  value={filter.appearanceRating}
                  onChange={(value) =>
                    onChangeFilter(value, "appearanceRating")
                  }
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.appearanceRatings.map(
                      (data) => ({
                        value: data,
                        label: data,
                      })
                    ),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>능력 등급</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="능력 등급"
                  style={{ width: 150 }}
                  value={filter.careerRating}
                  onChange={(value) => onChangeFilter(value, "careerRating")}
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.careerRatings.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>지역 (시,도)</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="지역 (시,도)"
                  style={{ width: 150 }}
                  value={filter.address}
                  onChange={(value) => onChangeFilter(value, "address")}
                  options={[
                    { value: "", label: "전체" },
                    { value: "서울", label: "서울" },
                    { value: "수도권", label: "수도권" },
                    { value: "비수도권", label: "비수도권" },
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>지역 (시,군,구)</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="지역 (시,군,구)"
                  style={{ width: 150 }}
                  disabled={!filter.address}
                  value={filter.addressSubGroup}
                  onChange={(value) => onChangeFilter(value, "addressSubGroup")}
                  options={
                    filter.address
                      ? [
                        { value: "", label: "전체" },
                        ...recommendedPartnerFilter.addressGroupMap[
                          filter.address as "서울" | "수도권"
                        ].addressSubGroups.map((data) => ({
                          value: data,
                          label: data,
                        })),
                      ]
                      : []
                  }
                />
              </div>
              <div>
                <Typography.Title level={5}>흡연</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="흡연"
                  style={{ width: 150 }}
                  value={filter.smokingStatus}
                  onChange={(value) => onChangeFilter(value, "smokingStatus")}
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.smokingStatuses.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>음주</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="음주"
                  style={{ width: 150 }}
                  value={filter.drinkingStatus}
                  onChange={(value) => onChangeFilter(value, "drinkingStatus")}
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.drinkingStatuses.map(
                      (data) => ({
                        value: data,
                        label: data,
                      })
                    ),
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>종교</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="종교"
                  style={{ width: 150 }}
                  value={filter.religion}
                  onChange={(value) => onChangeFilter(value, "religion")}
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.religions.map(
                      (data) => ({
                        value: data,
                        label: data,
                      })
                    ),
                  ]}
                />
              </div>
              
            </Flex>
            <Flex
              style={{
                marginBottom: "36px",
                gap: "28px",
              }}
            >
              <div>
                <Typography.Title level={5}>매칭 완료 여부</Typography.Title>
                <Select
                  defaultValue="false" // 기본값을 문자열로 변경
                  style={{ width: 150 }}
                  value={String(filter.hasNotMatching)} // Boolean 값을 문자열로 변환
                  onChange={(value) => onChangeFilter(value === "true", "hasNotMatching")} // 문자열을 다시 Boolean으로 변환
                  options={[
                    { value: "false", label: "전체" }, // value 값을 문자열로 변경
                    { value: "true", label: "매칭 이전" }, // value 값을 문자열로 변경
                  ]}
                />
              </div>
              <div>
                <Typography.Title level={5}>키 범위</Typography.Title>
                <InputNumber
                  min={130}
                  max={200}
                  value={filter.minHeight}
                  onChange={(value) =>
                    onChangeFilter(value as number, "minHeight")
                  }
                />
                cm ~{" "}
                <InputNumber
                  min={130}
                  max={200}
                  defaultValue={200}
                  value={filter.maxHeight}
                  onChange={(value) =>
                    onChangeFilter(value as number, "maxHeight")
                  }
                />
                cm
              </div>
              <div>
                <Typography.Title level={5}>나이 범위</Typography.Title>
                <InputNumber
                  min={20}
                  max={60}
                  defaultValue={20}
                  value={filter.minAge}
                  onChange={(value) =>
                    onChangeFilter(value as number, "minAge")
                  }
                />
                세 ~{" "}
                <InputNumber
                  min={20}
                  max={60}
                  defaultValue={60}
                  value={filter.maxAge}
                  onChange={(value) =>
                    onChangeFilter(value as number, "maxAge")
                  }
                />
                세
              </div>
              <div>
                <Typography.Title level={5}>추천인</Typography.Title>
                <Select
                  defaultValue=""
                  placeholder="흡연"
                  style={{ width: 150 }}
                  value={filter.refereeName}
                  onChange={(value) => onChangeFilter(value, "refereeName")}
                  options={[
                    { value: "", label: "전체" },
                    ...recommendedPartnerFilter.refereeNames.map((data) => ({
                      value: data,
                      label: data,
                    })),
                  ]}
                />
              </div>
            </Flex>
          </FlexColumn>
        </FilterBox>

        <List
          pagination={{
            pageSize: 10,
            onChange: onScrollTop,
            align: "start",
          }}
          bordered
          dataSource={matchingUser}
          header={<ListHeader>매칭 유저 관리 리스트</ListHeader>}
          renderItem={(
            {
              appearanceRating,
              income,
              careerDetail,
              careerRating,
              careerSubGroup,
              careerType,
              gender,
              name,
              userImages,
              userProfileId,
              incomeRating,
              univRating,
              hasMatching,
              userId
            }: MatchingUser,
            index
          ) => (
            <List.Item
              style={{
                display: "flex",
                justifyContent: "flex-start",
                gap: "12px",
              }}
            >
              <FlexColumn>
                <Flex>
                  <Flex>
                    <UserImageCarousel images={userImages} />
                  </Flex>
                  <Descriptions column={1}>
                    <Descriptions.Item label="매칭 여부">
                      {hasMatching ? "완료" : "이전"}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1}>
                    <Descriptions.Item label="이름">{name}</Descriptions.Item>
                    <Descriptions.Item label="성별">{gender}</Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1}>
                    <Descriptions.Item label="외모 레벨">
                      {appearanceRating}
                    </Descriptions.Item>
                    <Descriptions.Item label="직장">
                      {careerDetail}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1}>
                    <Descriptions.Item label="직업 레벨">
                      {careerRating}
                    </Descriptions.Item>
                    <Descriptions.Item label="직장선택지">
                      {careerSubGroup}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1}>
                    <Descriptions.Item label="직업군">
                      {careerType}
                    </Descriptions.Item>
                    <Descriptions.Item label="연봉">
                      {income || "입력 전"}
                    </Descriptions.Item>
                  </Descriptions>
                  <Descriptions column={1}>
                    <Descriptions.Item label="연봉 레벨">
                      {incomeRating}
                    </Descriptions.Item>
                    <Descriptions.Item label="학교 레벨">
                      {univRating}
                    </Descriptions.Item>
                  </Descriptions>
                </Flex>
                <Flex>
                  <Button type="primary">
                    <a href={`/matching/${userProfileId}`}>
                      매칭 관리 페이지로 이동
                    </a>
                  </Button>
                  {
                    (hasMatching &&
                      <MatchingViewBtn
                        userName={name}
                        userId={userId}
                      />
                    )
                  }
                </Flex>
              </FlexColumn>
            </List.Item>
          )}
        />
      </Paper>
    </MatchingLayout>
  );
};

const Paper = styled.div`
  padding: 16px;
  background-color: white;
  border-radius: 12px;
`;

const FilterBox = styled.div`
  width: 100%;
  display: flex;
  gap: 24px;
  margin: 16px 0;
  padding: 16px;
`;

const ListHeader = styled.div`
  font-weight: 700;
  font-size: 16px;
`;

const Flex = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
`;

export default MatchingHome;
