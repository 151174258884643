import React, {useCallback, useEffect, useRef} from 'react';
import {Button, Card, Input, Space} from 'antd';
import {ReloadOutlined, SearchOutlined} from '@ant-design/icons';


export interface FilterValues {
    name: string;
    gender: string;
    overallSocialRating: string;
    appearanceRating: string;
    univRating: string;
    preferenceType: string;
    userId: string;
}

interface RecommendationGlobalTableFilterProps {
    filter: FilterValues;
    onFilterChange: (values: Partial<FilterValues>) => void;
    onReset: () => void;
}

// 커스텀 debounce 훅
function useDebounce<T extends (...args: any[]) => void>(
    callback: T,
    delay: number
) {
    const timeoutRef = useRef<NodeJS.Timeout>();

    useEffect(() => {
        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    return useCallback(
        (...args: Parameters<T>) => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }

            timeoutRef.current = setTimeout(() => {
                callback(...args);
            }, delay);
        },
        [callback, delay]
    );
}


const RecommendationGlobalTableFilter: React.FC<RecommendationGlobalTableFilterProps> = ({
                                                                                             filter,
                                                                                             onFilterChange,
                                                                                             onReset
                                                                                         }) => {
    // 디바운스된 필터 변경 함수 생성
    const debouncedFilterChange = useDebounce((key: keyof FilterValues, value: string) => {
        onFilterChange({ [key]: value });
    }, 500);

    return (
        <Card style={{marginBottom: 8}}>
            <Space size="middle" style={{display: 'flex', alignItems: 'center'}}>
                <div style={{width: 200}}>
                    <div style={{marginBottom: 4, fontSize: 14, color: '#666'}}>유저 아이디 검색</div>
                    <Input
                        placeholder="유저 아이디를 입력하세요."
                        value={filter.userId}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            // 입력 필드의 값은 즉시 업데이트
                            onFilterChange({ userId: newValue });
                            // API 호출은 디바운스 처리
                            debouncedFilterChange('userId', newValue);
                        }}
                        prefix={<SearchOutlined style={{color: '#999'}}/>}
                        allowClear
                    />
                </div>
                <div style={{width: 200}}>
                    <div style={{marginBottom: 4, fontSize: 14, color: '#666'}}>유저 이름 검색</div>
                    <Input
                        placeholder="유저 이름을 입력하세요."
                        value={filter.name}
                        onChange={(e) => {
                            const newValue = e.target.value;
                            onFilterChange({ name: newValue });
                            debouncedFilterChange('name', newValue);
                        }}
                        prefix={<SearchOutlined style={{color: '#999'}}/>}
                        allowClear
                    />
                </div>
                <Button
                    icon={<ReloadOutlined/>}
                    onClick={onReset}
                    style={{marginTop: 22}}  // 레이블 높이만큼 아래로 내림
                >
                    초기화
                </Button>

            </Space>
        </Card>
    );
};

export default RecommendationGlobalTableFilter;