import {Card as AntCard, Descriptions, Image, Typography} from 'antd';
import React from 'react';
import {RecommendedUser} from "../../../utils/api/recommend";
import Title from "antd/lib/typography/Title";
import styled from "@emotion/styled";


const RecommendedUserProfileCard = ({data}: { data: RecommendedUser }) => {

    return (
        <div>
            <Typography.Title level={2}>{data.name}</Typography.Title>

            {/* 매력 정보 섹션 */}
            <Card
                title="매력 정보"
                className="mb-4"
                headStyle={{backgroundColor: '#6c757d', color: 'white'}}
            >
                <SectionWrapper>
                    <SubtitleArea>
                        <Title level={5} style={{color: '#666'}}>
                            사진&영상
                        </Title>
                    </SubtitleArea>
                    <ContentArea>
                        <Card>
                            <ImageGridContainer>
                                    {data.userProfileImages.map((image, index) => (
                                        <ImageWrapper key={index}>
                                            <Image
                                                src={image}
                                                alt={`Image ${index}`}
                                                width={300}
                                                height={300}
                                                style={{objectFit: 'cover', borderRadius: '8px'}}
                                                preview={{
                                                    width: 800,  // 프리뷰 모달의 너비
                                                    height: 600, // 프리뷰 모달의 높이
                                                    mask: <div>클릭하여 크게보기</div>, // 선택적: 호버시 보여줄 텍스트
                                                }}
                                            />
                                        </ImageWrapper>
                                    ))}
                            </ImageGridContainer>
                        </Card>
                    </ContentArea>
                </SectionWrapper>

                <SectionWrapper>
                    <SubtitleArea>
                        <Title level={5} style={{color: '#666'}}>
                            소개글
                        </Title>
                    </SubtitleArea>

                    <ContentArea>
                        <Card>
                            <div className="mt-4">
                                <p>{data.appealPoint}</p>
                            </div>
                        </Card>
                    </ContentArea>
                </SectionWrapper>

            </Card>

            <div style={{display: 'grid', gridTemplateColumns: '1fr 1fr 1fr 1fr', gap: '16px'}}>
                <Card
                    title="기본 정보"
                    headStyle={{backgroundColor: '#6c757d', color: 'white'}}
                >
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="나이">{data.age}세</Descriptions.Item>
                        <Descriptions.Item label="키">{data.height}cm</Descriptions.Item>
                        <Descriptions.Item label="외모">{data.appearanceRating}</Descriptions.Item>
                        <Descriptions.Item label="능력">{data.overallSocialRating}</Descriptions.Item>
                        <Descriptions.Item label="학력">{data.univRating}</Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card
                    title="직업/학력 정보"
                    headStyle={{backgroundColor: '#6c757d', color: 'white'}}
                >
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="직업">{data.careerSubGroup}</Descriptions.Item>
                        <Descriptions.Item label="직장명">{data.careerDetail}</Descriptions.Item>
                        <Descriptions.Item label="연봉">{data.income ?? "(연봉 미인증)"}</Descriptions.Item>
                        <Descriptions.Item label="학교명">{data.univName !== "" ? data.univName : "(학교 미인증)"}</Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card
                    title="성향 정보"
                    headStyle={{backgroundColor: '#6c757d', color: 'white'}}
                >
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="종교">{data.religion}</Descriptions.Item>
                        <Descriptions.Item label="흡연">{data.smokingStatus}</Descriptions.Item>
                        <Descriptions.Item label="음주">{data.drinkingStatus}</Descriptions.Item>
                    </Descriptions>
                </Card>

                <Card
                    title="이상형 정보"
                    headStyle={{backgroundColor: '#6c757d', color: 'white'}}
                >
                    <Descriptions column={1} bordered>
                        <Descriptions.Item label="선호 타입">{data.preferenceType}</Descriptions.Item>
                        <Descriptions.Item label="이성의 나이" span={1}>
                            {Number(data?.age ?? 0) -
                                Number(data?.partnerUnderAge ?? 0)}세 ~
                            {Number(data?.age ?? 0) +
                                Number(data?.partnerUpperAge ?? 0)}세
                        </Descriptions.Item>
                        <Descriptions.Item label="이성의 키" span={1}>{data?.partnerHeightRange.at(0)}cm
                            ~ {data?.partnerHeightRange.at(-1)}cm</Descriptions.Item>
                        <Descriptions.Item label="이상의 종교">{data.partnerReligion.length > 0 ? (
                            <ul className="list-disc ml-4">
                                {data.partnerReligion.map(religion => (
                                    <li key={religion}>{religion}</li>
                                ))}
                            </ul>
                        ) : '-'}</Descriptions.Item>
                        <Descriptions.Item label="이상의 음주">{data.partnerDrinkingStatus?? "-"}</Descriptions.Item>
                        <Descriptions.Item label="이성의 흡연">{data.partnerSmokingStatus?? "-"}</Descriptions.Item>
                    </Descriptions>
                </Card>
            </div>
        </div>
    );
};

const SectionWrapper = styled.div`
    display: flex;
    gap: 24px;
    margin-bottom: 16px;
`;

const SubtitleArea = styled.div`
    width: 96px;
    flex-shrink: 0;
`;

const ContentArea = styled.div`
    flex-grow: 1;
`;

const Card = styled(AntCard)`
    width: 100%;
`;

const ImageGridContainer = styled.div`
    display: grid;
    grid-template-columns: repeat(auto-fill, 300px);
    gap: 16px;
    width: 100%;
`;


const ImageWrapper = styled.div`
    flex-shrink: 0;
`;


export default RecommendedUserProfileCard;