import React from 'react';
import {
    Line,
    LineChart,
    Bar,
    BarChart,
    PieChart,
    Pie,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    Cell
} from 'recharts';
import MatchLayout from "./components/RecommendationLayout";

// 더미 데이터
const monthlyStats = [
    {month: '2024-01', recommendations: 120, successRate: 75, activeUsers: 80},
    {month: '2024-02', recommendations: 150, successRate: 78, activeUsers: 95},
    {month: '2024-03', recommendations: 180, successRate: 82, activeUsers: 110},
    {month: '2024-04', recommendations: 200, successRate: 85, activeUsers: 130},
];

const userAttributeStats = {
    age: [
        {name: '20대', value: 35},
        {name: '30대', value: 40},
        {name: '40대', value: 15},
        {name: '50대', value: 10},
    ],
    education: [
        {name: '고졸', value: 20},
        {name: '대졸', value: 45},
        {name: '석사', value: 25},
        {name: '박사', value: 10},
    ],
    occupation: [
        {name: '회사원', value: 40},
        {name: '전문직', value: 25},
        {name: '자영업', value: 20},
        {name: '기타', value: 15},
    ]
};

const matchingStats = [
    {category: '외모', avgScore: 85, matchCount: 150},
    {category: '성격', avgScore: 78, matchCount: 180},
    {category: '취미', avgScore: 92, matchCount: 130},
    {category: '가치관', avgScore: 88, matchCount: 160},
    {category: '라이프스타일', avgScore: 82, matchCount: 140},
];

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// type TabPanelProps = {
//     children: React.ReactNode;
//     value: number;
//     index: number;
// };

// const TabPanel = ({children, value, index}: TabPanelProps) => {
//     return value === index ? children : null;
// };

type CardProps = {
    children: React.ReactNode;
    title?: string;
};

const Card = ({children, title}: CardProps) => (
    <div className="bg-white rounded-lg shadow p-6">
        {title && <h3 className="text-lg font-medium mb-4">{title}</h3>}
        {children}
    </div>
);

const RecommendationStatistics = () => {
    // const [activeTab, setActiveTab] = useState(0);
    // const [dateRange, setDateRange] = useState({start: '', end: ''});

    return (
        <MatchLayout>
            <div className="p-6 bg-gray-50 min-h-screen">
                <div className="mb-6">
                    <h1 className="text-2xl font-bold mb-4">추천 시스템 통계</h1>
                    {/* 날짜 선택 부분 유지 */}
                </div>

                {/* 월별 통계 */}
                <div className="mb-6">
                    <Card title="월별 추천 통계">
                        <div style={{width: '100%', height: 400}}>
                            <ResponsiveContainer>
                                <LineChart data={monthlyStats} margin={{top: 5, right: 30, left: 20, bottom: 5}}>
                                    <CartesianGrid strokeDasharray="3 3"/>
                                    <XAxis dataKey="month"/>
                                    <YAxis/>
                                    <Tooltip/>
                                    <Legend/>
                                    <Line
                                        type="monotone"
                                        dataKey="recommendations"
                                        stroke="#8884d8"
                                        name="추천 수"
                                        activeDot={{r: 8}}
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="successRate"
                                        stroke="#82ca9d"
                                        name="성공률"
                                    />
                                    <Line
                                        type="monotone"
                                        dataKey="activeUsers"
                                        stroke="#ffc658"
                                        name="활성 사용자"
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>
                </div>

                {/* 유저 속성 분포 */}
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
                    {/* 연령대 분포 */}
                    <Card title="연령대 분포">
                        <div style={{width: '100%', height: 300}}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={userAttributeStats.age}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                        fill="#8884d8"
                                        label
                                    >
                                        {userAttributeStats.age.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                        ))}
                                    </Pie>
                                    <Tooltip/>
                                    <Legend/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>

                    {/* 학력 분포 */}
                    <Card title="학력 분포">
                        <div style={{width: '100%', height: 300}}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={userAttributeStats.education}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                        fill="#8884d8"
                                        label
                                    >
                                        {userAttributeStats.education.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                        ))}
                                    </Pie>
                                    <Tooltip/>
                                    <Legend/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>

                    {/* 직업 분포 */}
                    <Card title="직업 분포">
                        <div style={{width: '100%', height: 300}}>
                            <ResponsiveContainer>
                                <PieChart>
                                    <Pie
                                        data={userAttributeStats.occupation}
                                        dataKey="value"
                                        nameKey="name"
                                        cx="50%"
                                        cy="50%"
                                        outerRadius={80}
                                        fill="#8884d8"
                                        label
                                    >
                                        {userAttributeStats.occupation.map((entry, index) => (
                                            <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]}/>
                                        ))}
                                    </Pie>
                                    <Tooltip/>
                                    <Legend/>
                                </PieChart>
                            </ResponsiveContainer>
                        </div>
                    </Card>
                </div>

                {/* 매칭 통계 */}
                <Card title="매칭 카테고리별 통계">
                    <div style={{width: '100%', height: 400}}>
                        <ResponsiveContainer>
                            <BarChart data={matchingStats}>
                                <CartesianGrid strokeDasharray="3 3"/>
                                <XAxis dataKey="category"/>
                                <YAxis yAxisId="left" orientation="left" stroke="#8884d8"/>
                                <YAxis yAxisId="right" orientation="right" stroke="#82ca9d"/>
                                <Tooltip/>
                                <Legend/>
                                <Bar yAxisId="left" dataKey="avgScore" name="평균 점수" fill="#8884d8"/>
                                <Bar yAxisId="right" dataKey="matchCount" name="매칭 수" fill="#82ca9d"/>
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                </Card>
            </div>
        </MatchLayout>
    );
};

export default RecommendationStatistics;