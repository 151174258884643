import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import { List, Typography, notification } from "antd";
import AdditionalRecommendationProfileCard from "./AdditionalRecommendationProfileCard";
import PriorityProfileCardSecond from "./MatchedUserPriorityProfileCard";
import {
  getRecommendedUserById,
  getRecommendationPartnerFilterCategory,
} from "../../../utils/api/recommend";
import {
  getAdditionalMatchingNominee,
  postFreeAdditionalRecommendation,
} from "../../../utils/api/matching";
import {
  getMatchedUserPriority,
  GetMatchedUserPriority,
} from "../../../utils/api/matching";
import { useRef, useState } from "react";
import Loading from "../../../common/components/Loading";

interface AdditionalRecommendationSectionProps {
  filter: Filter;
}

interface Filter {
  name: string;
  minAge: number;
  maxAge: number;
  minHeight: number;
  maxHeight: number;
  appearanceRating: string;
  careerRating: string;
  smokingStatus: string;
  drinkingStatus: string;
  address: string;
  addressSubGroup: string;
  refereeName: string;
}

const AdditionalRecommendationSection = ({
  filter,
}: AdditionalRecommendationSectionProps) => {
  const [api, contextHolder] = notification.useNotification();

  const qc = useQueryClient();

  const [selectedPriorityUser, setSelectedPriorityUser] = useState<{
    name: string;
    profileId: number | null;
  }>({
    name: "",
    profileId: null,
  });

  const onPriorityUserClick = (name: string, userProfileId: number) => {
    if (selectedPriorityUser.profileId === userProfileId) {
      setSelectedPriorityUser({
        name: "",
        profileId: null,
      });
    } else {
      setSelectedPriorityUser({
        name,
        profileId: userProfileId,
      });
    }
  };

  const filterMatchedUserPriorityByChoiceRanking = (
    partners: GetMatchedUserPriority[]
  ) => {
    return partners.filter(
      (partner) => partner.choiceRanking !== "선택하지 않음"
    );
  };

  const openNotificationWithIconByRecommend = (name: string) => {
    api["success"]({
      message: "추가 추천 성공!",
      description: `${name}님을 추가 추천하는데 성공했어요`,
      duration: 3,
    });
  };

  const openNotificationWithIconByRecommendFail = (name: string) => {
    api["error"]({
      message: "추가 추천 실패!",
      description: `${name}님을 추가 추천하는데 실패했어요.`,
      duration: 3,
    });
  };

  // params 여기서 id 는 유저 프로필 아이디임
  const params = useParams<{ id: string }>();
  const enableRecommendBoxRef = useRef<HTMLDivElement>(null);
  const onScrollTop = () => {
    if (enableRecommendBoxRef.current)
      enableRecommendBoxRef.current.scrollTo(0, 0);
  };

  const { data: recommendedPartnerFilter } = useQuery({
    queryKey: ["recommendationPartnerFilterCategory", params.id],
    queryFn: () => getRecommendationPartnerFilterCategory(),
  });

  const { data: matchedUsers } = useQuery({
    queryKey: ["matched-users", params.id],
    queryFn: () =>
      getRecommendedUserById({
        "user-profile-id": Number(params.id),
      }),
  });

  // 세번째 칸 호출 api 메서드
  const { data: matchedPriorityPartners } = useQuery({
    queryKey: ["matchedPriorityPartners", selectedPriorityUser],
    enabled: selectedPriorityUser.profileId !== null,
    queryFn: () => getMatchedUserPriority(selectedPriorityUser.profileId || 0),
  });

  // 추가 매칭 가능한 유저 조회
  const { data: additionalMatchingNominee } = useQuery({
    queryKey: ["getAdditionalMatchingNominee", params.id, filter],
    enabled: !!matchedUsers?.userId,
    queryFn: () =>
      getAdditionalMatchingNominee({
        userId: userId,
        ...filter,
      }),
  });

  const {
    mutate: onFreeRecommendation,
    isPending: isFreeRecommendationPending,
  } = useMutation({
    mutationFn: (partnerId: number) =>
      postFreeAdditionalRecommendation({
        userId: matchedUsers?.userId || 0,
        partnerUserId: partnerId,
      }),
    onSuccess: () => {
      openNotificationWithIconByRecommend(matchedUsers?.name || "");
      qc.invalidateQueries({ queryKey: ["matchingPriorityPartners"] });
      qc.invalidateQueries({ queryKey: ["matchedPriorityPartners"] });
      qc.invalidateQueries({ queryKey: ["matchUser"] });
      qc.invalidateQueries({ queryKey: ["userMatchingPartners"] });
    },
    onError: () => openNotificationWithIconByRecommendFail("123"),
  });

  if (!matchedUsers || !recommendedPartnerFilter) return <></>;

  const { name, userId } = matchedUsers;

  return (
    <>
      {contextHolder}
      <Section>
        <Typography.Title level={2}>
          추가 매칭 가능한 유저 목록 :{" "}
          {additionalMatchingNominee && additionalMatchingNominee.length}{" "}
        </Typography.Title>
        <RecommendationPartnerSection ref={enableRecommendBoxRef}>
          {isFreeRecommendationPending ? (
            <Loading />
          ) : (
            <List
              pagination={{ pageSize: 10, onChange: onScrollTop }}
              bordered
              dataSource={additionalMatchingNominee}
              renderItem={(partner) => {
                if (partner.refundUser === true) return <></>;
                return (
                  <AdditionalRecommendationProfileCard
                    key={userId}
                    userId={userId}
                    userName={name}
                    partner={partner}
                    apiKey={params.id}
                    onPriorityUserClick={onPriorityUserClick}
                    onFreeRecommendation={() =>
                      onFreeRecommendation(partner.userId)
                    }
                  />
                );
              }}
            />
          )}
        </RecommendationPartnerSection>
      </Section>
      <Section>
        <Typography.Title level={2}>
          {selectedPriorityUser.profileId ? (
            <div>
              "{selectedPriorityUser.name}"님이 선택한 우선 순위 :{" "}
              {matchedPriorityPartners &&
                filterMatchedUserPriorityByChoiceRanking(
                  matchedPriorityPartners
                ).length}{" "}
            </div>
          ) : (
            "우선 순위 확인 버튼을 클릭해 우선순위를 확인하세요"
          )}
        </Typography.Title>
        <RecommendedPartnerSection ref={enableRecommendBoxRef}>
          {matchedPriorityPartners && (
            <List
              pagination={{ pageSize: 10, onChange: onScrollTop }}
              bordered
              dataSource={matchedPriorityPartners}
              renderItem={(partner) => {
                if (partner.choiceRanking === "선택하지 않음") return;
                return <PriorityProfileCardSecond partner={partner} />;
              }}
            />
          )}
        </RecommendedPartnerSection>
      </Section>
    </>
  );
};

export default AdditionalRecommendationSection;

const Section = styled.div`
  height: auto;

  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 12px;
`;

const RecommendationPartnerSection = styled.div`
  max-height: 1000px;
  overflow-y: auto;
`;

const RecommendedPartnerSection = styled.div`
  max-height: 1000px;
  overflow-y: auto;
`;
