import UserLayout from "./components/UserLayout";
import styled from "@emotion/styled";
import { List, Typography, notification } from "antd";
import { useQuery } from "@tanstack/react-query";
import { getUnivInspection } from "../../utils/api/user";
import UnivInspectionCard from "./components/UnivInspectionCard";
import { useState } from "react";
import Loading from "../../common/components/Loading";

const Univ = () => {
  const [isLoading, setIsLoading] = useState(false);
  const onChangeLoading = (loading: boolean) => {
    setIsLoading(loading);
  };

  const { data } = useQuery({
    queryKey: ["univ"],
    queryFn: () => getUnivInspection(),
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (name: string) => {
    api["success"]({
      message: "분류 완료!",
      description: `${name}님의 학교 분류가 완료되었어요`,
      duration: 3,
    });
  };

  if (isLoading)
    return (
      <Paper>
        <Loading />
      </Paper>
    );

  return (
    <UserLayout>
      {contextHolder}
      <Paper>
        <Typography.Title level={2}>유저 학교 분류</Typography.Title>
        <List
          header={<div>학교 분류</div>}
          bordered
          pagination={{
            pageSize: 5,
            align: "start",
          }}
          dataSource={data}
          renderItem={(item) => (
            <UnivInspectionCard
              univData={item}
              openNotificationWithIcon={openNotificationWithIcon}
              onChangeLoading={onChangeLoading}
            />
          )}
        />
      </Paper>
    </UserLayout>
  );
};

const Paper = styled.div`
  background-color: white;
  border-radius: 12px;

  padding: 16px;
`;

export default Univ;
