import { useQuery } from "@tanstack/react-query";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled";
import {
  Descriptions,
  Typography,
  notification,
  Segmented,
  Button,
} from "antd";
import UserImageCarousel from "../user/components/UserImageCarousel";
import { useState } from "react";
import MatchingLayout from "./components/MatchingLayout";
import MatchingViewBtn from "./components/MatchingViewBtn";
import { getMatchingUserByProfileId } from "../../utils/api/matching";
import {
  getRecommendationPartnerFilterCategory,
  getRecommendedUserById,
} from "../../utils/api/recommend";
import { MatchingFilter } from "../../utils/api/matching";
import PriorityDetailSection from "./components/PriorityDetailSection";
import AdditionalRecommendationSection from "./components/AdditionalRecommendationSection";
import AdditionalRecommendationFilter from "./components/AdditionalRecommendationFilter";

const MatchingDetail = () => {
  // params 여기서 id 는 유저 프로필 아이디임
  const params = useParams<{ id: string }>();

  const [api, contextHolder] = notification.useNotification();
  const [isPriorityMode, setIsPriorityMode] = useState(true);

  const [filter, setFilter] = useState<MatchingFilter>({
    gender: "MALE",
    name: "",
    minAge: 19,
    maxAge: 60,
    minHeight: 130,
    maxHeight: 200,
    appearanceRating: "",
    careerRating: "",
    smokingStatus: "",
    drinkingStatus: "",
    religion: "",
    address: "",
    addressSubGroup: "",
    refereeName: "",
    recommendationCount: 12,
    hasNotMatching: false,
  });

  const onChangeFilter = (value: string | number | boolean, key: string) => {
    if (key === "address") {
      setFilter((prev) => ({
        ...prev,
        [key]: value as string,
        addressSubGroup: "",
      }));
      return;
    }

    // "hasNotMatching" 키에 대한 조건문 추가 - boolean 값을 처리
    if (key === "hasNotMatching") {
      setFilter((prev) => ({
        ...prev,
        [key]: value as boolean, // "hasNotMatching"의 경우, value는 boolean으로 처리
      }));
      return;
    }
    setFilter((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const openNotificationWithIconByRecommend = (name: string) => {
    api["success"]({
      message: "매칭 성공!",
      description: `${name}님의 매칭을 성공했어요`,
      duration: 3,
    });
  };

  const openNotificationWithIconByRecommendFail = (name: string) => {
    api["error"]({
      message: "매칭 실패!",
      description: `${name}님을 매칭하는데 실패했어요.`,
      duration: 3,
    });
  };

  const { data: matchUser } = useQuery({
    queryKey: ["matchUser", params.id],
    queryFn: () =>
      getMatchingUserByProfileId({
        profileId: Number(params.id),
      }),
  });

  const { data: recommendedPartnerFilter } = useQuery({
    queryKey: ["recommendationPartnerFilterCategory"],
    queryFn: () => getRecommendationPartnerFilterCategory(),
  });

  const { data: kakaoId } = useQuery({
    queryKey: ["matched-users", params.id],
    queryFn: () =>
      getRecommendedUserById({
        "user-profile-id": Number(params.id),
      }),
  });

  if (!matchUser || !recommendedPartnerFilter) return <></>;

  const {
    appearanceRating,
    careerDetail,
    careerRating,
    careerSubGroup,
    careerType,
    gender,
    income,
    incomeRating,
    name,
    univRating,
    userImages,
    address,
    addressSubGroup,
    religion,
    partnerUnderAge,
    partnerUpperAge,
    idOkPartnerSameAge,
    partnerHeightRange,
    smokingStatus,
    drinkingStatus,
    appealPoint,
    referencePoint,
    userRequest,
    idealType,
    height,
    age,
    finalAcademic,
    univName,
    refereeName,
    funnel,
    phoneNumber,
  } = matchUser;

  return (
    <MatchingLayout>
      {contextHolder}
      <Paper>
        <Flex>
          <Typography.Title level={2}>매칭 큐 상태 조회</Typography.Title>
          <Segmented
            options={[
              { label: "첫 추천 유저 조회", value: "On" },
              { label: "추가 추천 유저 조회", value: "Off" },
            ]}
            value={isPriorityMode ? "On" : "Off"}
            onChange={() => setIsPriorityMode(!isPriorityMode)}
          />
        </Flex>
        {!isPriorityMode && (
          <AdditionalRecommendationFilter
            filter={filter}
            setFilter={setFilter}
            onChangeFilter={onChangeFilter}
            recommendedPartnerFilter={recommendedPartnerFilter}
          />
        )}

        <Flex
          style={{
            alignItems: "flex-start",
          }}
        >
          <Section>
            <Flex
              style={{
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Typography.Title level={2}>유저 정보</Typography.Title>
              <Button type="primary">
                <a
                  href={`${process.env.REACT_APP_JUSUNKING_URL}matching/profile?id=${kakaoId?.kakaoAppUserId}`}
                >
                  회원 매칭 프로필 페이지 보기
                </a>
              </Button>
            </Flex>
            <UserImageCarousel images={userImages} />
            <FlexColumn
              style={{
                paddingTop: "32px",
                alignItems: "flex-start",
              }}
            >
              {matchUser.hasMatching && (
                <MatchingViewBtn
                  userName={matchUser.name}
                  userId={matchUser.userId}
                />
              )}
            </FlexColumn>
            <Flex
              style={{
                paddingTop: "32px",
                alignItems: "flex-start",
              }}
            >
              <FlexColumn>
                <Descriptions column={1} title="유저 정보">
                  <Descriptions.Item label="이름">{name}</Descriptions.Item>
                  <Descriptions.Item label="성별">{gender}</Descriptions.Item>
                  <Descriptions.Item label="키">{height}</Descriptions.Item>
                  <Descriptions.Item label="나이">{age}</Descriptions.Item>
                  <Descriptions.Item label="전화번호">
                    {phoneNumber}
                  </Descriptions.Item>
                  <Descriptions.Item label="외모 등급">
                    {appearanceRating}
                  </Descriptions.Item>
                  <Descriptions.Item label="능력 등급">
                    {careerRating}
                  </Descriptions.Item>

                  <Descriptions.Item label="매칭인">
                    {refereeName || "입력 전"}
                  </Descriptions.Item>
                  <Descriptions.Item label="유입 경로">
                    {funnel || "입력 전"}
                  </Descriptions.Item>
                  <Descriptions.Item label="종교">{religion}</Descriptions.Item>
                  <Descriptions.Item label="도시">{address}</Descriptions.Item>
                  <Descriptions.Item label="거주지">
                    {addressSubGroup}
                  </Descriptions.Item>
                  <Descriptions.Item label="흡연 / 음주 여부">
                    {smokingStatus} / {drinkingStatus}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1}></Descriptions>
                <Descriptions column={1}>
                  <Descriptions.Item label="직장">
                    {careerDetail}
                  </Descriptions.Item>
                  <Descriptions.Item label="직장명">
                    {careerSubGroup}
                  </Descriptions.Item>

                  <Descriptions.Item label="직업군">
                    {careerType}
                  </Descriptions.Item>
                  <Descriptions.Item label="연봉">
                    {income || "입력 전"}
                  </Descriptions.Item>
                  <Descriptions.Item label="자기소개">
                    {appealPoint}
                  </Descriptions.Item>
                </Descriptions>
              </FlexColumn>
              <FlexColumn>
                <Descriptions column={1} title="선호 조건">
                  <Descriptions.Item label="연상 선호">
                    {partnerUpperAge}
                  </Descriptions.Item>
                  <Descriptions.Item label="연하 선호">
                    {partnerUnderAge}
                  </Descriptions.Item>
                  <Descriptions.Item label="동갑 선호">
                    {idOkPartnerSameAge || "-"}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1}>
                  <Descriptions.Item label="선호 키">
                    {partnerHeightRange}
                  </Descriptions.Item>
                  <Descriptions.Item label="요청 사항">
                    {userRequest || "-"}
                  </Descriptions.Item>
                </Descriptions>
                <Descriptions column={1} title={"기타정보"}>
                  <Descriptions.Item label="최종 학력">
                    {finalAcademic}
                  </Descriptions.Item>
                  <Descriptions.Item label="학교명">
                    {univName}
                  </Descriptions.Item>

                  <Descriptions.Item label="연봉 레벨">
                    {incomeRating}
                  </Descriptions.Item>
                  <Descriptions.Item label="학교 레벨">
                    {univRating}
                  </Descriptions.Item>
                  <Descriptions.Item label="특징">
                    {referencePoint}
                  </Descriptions.Item>
                  <Descriptions.Item>{idealType}</Descriptions.Item>
                </Descriptions>
              </FlexColumn>
            </Flex>
          </Section>
          {isPriorityMode ? (
            <PriorityDetailSection
              userProfileId={Number(params.id)}
              openNotificationWithIconByRecommend={
                openNotificationWithIconByRecommend
              }
              openNotificationWithIconByRecommendFail={
                openNotificationWithIconByRecommendFail
              }
              matchUser={matchUser}
            />
          ) : (
            <AdditionalRecommendationSection filter={filter} />
          )}
        </Flex>
      </Paper>
    </MatchingLayout>
  );
};

const Paper = styled.div`
  padding: 16px;
  background-color: white;
  border-radius: 12px;
`;

const Section = styled.div`
  height: auto;
  width: 500px;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 12px;
`;

const Flex = styled.div`
  display: flex;
  gap: 12px;
  align-items: center;
  justify-content: center;
`;

const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-items: center;
  justify-content: flex-start;
`;

export default MatchingDetail;
