import {
    Button,
    Flex,
    Image,
    Modal,
    Typography,
} from "antd";
import { useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { putUserRepresentativeImages } from "../../../utils/api/user";
import styled from "@emotion/styled";

interface Props {
    userProfileId: number;
    userImages: string[];
    userName: string;
    openNotificationWithIcon: (name: string) => void;
}

const AppearanceImagesUpdateBtnWithLabel = ({
    userProfileId,
    userImages,
    userName,
    openNotificationWithIcon,
}: Props) => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const [representativeImages, setRepresentativeImages] = useState<string[]>(
        []
    );

    const onAddRepresentativeImage = (image: string) => {
        // 중복 추가 방지
        if (representativeImages.includes(image)) return;

        setRepresentativeImages((prev) => [...prev, image]);
    };

    const onRemoveRepresentativeImage = (image: string) => {
        setRepresentativeImages((prev) =>
            prev.filter((prevImage) => prevImage !== image)
        );
    };

    const qc = useQueryClient();
    const { mutate, status } = useMutation({
        mutationFn: () =>
            putUserRepresentativeImages({
                userProfileId,
                representativeImages,
            }),
        onSuccess: () => {
            openNotificationWithIcon(userName);
            qc.invalidateQueries({ queryKey: ["users"] });
            handleCancel();
        }
    });


    if (status === "pending") return <div></div>;

    return (
        <div>
            <Button type="primary" onClick={showModal}>
                유저 대표 사진 수정
            </Button>
            <Modal
                open={isModalOpen}
                onOk={handleOk}
                onCancel={handleCancel}
                okText="닫기"
                width={1680}
                title="유저 대표 사진 수정"
                footer={[
                    <Button key="submit" type="primary" onClick={handleOk}>
                        닫기
                    </Button>,
                ]}
            >
                <CardImageBox>
                    {userImages.map((src, index) => {
                        return (
                            <div key={`representative_image_${index}`}>
                                <Image
                                    style={{
                                        width: 400,
                                    }}
                                    width={400}
                                    alt={`image_${index}`}
                                    src={src}
                                />
                                <Flex
                                    justify="center"
                                    gap="small"
                                    style={{
                                        paddingTop: 12,
                                    }}
                                >
                                    <Button
                                        type="primary"
                                        onClick={() => onAddRepresentativeImage(src)}
                                    >
                                        대표 이미지에 추가
                                    </Button>
                                </Flex>
                            </div>
                        );
                    })}
                </CardImageBox>

                <Typography.Title level={5}>대표 이미지 리스트</Typography.Title>
                <Flex
                    style={{
                        paddingBottom: 24,
                        gap: 12,
                    }}
                >
                    {representativeImages.length > 0 ? (
                        representativeImages.map((src, index) => {
                            return (
                                <div
                                    key={`selected_image_${index}`}
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: 12,
                                    }}
                                >
                                    <Image
                                        style={{
                                            width: 150,
                                            height: 150,
                                        }}
                                        width={150}
                                        alt={`image_${index}`}
                                        src={src}
                                    />
                                    <Button
                                        type="primary"
                                        danger
                                        onClick={() => onRemoveRepresentativeImage(src)}
                                    >
                                        대표 이미지에서 제외
                                    </Button>
                                </div>
                            );
                        })
                    ) : (
                        <Flex
                            justify="center"
                            align="center"
                            style={{
                                height: 150,
                                backgroundColor: "#f0f0f0",
                            }}
                        >
                            대표 이미지가 없습니다.
                        </Flex>
                    )}
                </Flex>
                
                <SubmitContainer>
                    <Button
                        type="primary"
                        icon={<CheckCircleOutlined />}
                        disabled={representativeImages.length === 0}
                        size="large"
                        onClick={() => mutate()}
                    >사진 수정하기
                    </Button>
                </SubmitContainer>
            </Modal>
        </div>
    );
};
const CardImageBox = styled.div`
width: 100%;
display: flex;
justify-content: space-between;
align-items: center;
overflow-x: scroll;
padding-bottom: 20px;
gap: 12px;
`;

const SubmitContainer = styled.div`
display: flex;
padding-top: 20px;
`;


export default AppearanceImagesUpdateBtnWithLabel;