import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import styled from "@emotion/styled";
import {  List, Typography } from "antd";
import { useRef, useState } from "react";
import {
    MatchingUser,
    getMatchingUserPriority,
    getMatchedUserPriority,
    GetMatchingUserPriority,
    GetMatchedUserPriority,
    postMatching,
    postTmpMatching
} from "../../../utils/api/matching";
import PriorityProfileCard from "./MatchingUserPriorityProfileCard";
import PriorityProfileCardSecond from "./MatchedUserPriorityProfileCard";
import Loading from "../../../common/components/Loading";

interface PriorityDetailSectionProps {
    userProfileId: number;
    openNotificationWithIconByRecommend: (name: string) => void
    openNotificationWithIconByRecommendFail: (name: string) => void
    matchUser: MatchingUser
}

const PriorityDetailSection = ({
    userProfileId,
    openNotificationWithIconByRecommend,
    openNotificationWithIconByRecommendFail,
    matchUser
}: PriorityDetailSectionProps) => {

    const enableRecommendBoxRef = useRef<HTMLDivElement>(null);
    const onScrollTop = () => {
        if (enableRecommendBoxRef.current)
            enableRecommendBoxRef.current.scrollTo(0, 0);
    };


    const [selectedPriorityUser, setSelectedPriorityUser] = useState<{
        name: string;
        profileId: number | null;
    }>({
        name: "",
        profileId: null,
    });


    const onPriorityUserClick = (name: string, userProfileId: number) => {
        if (selectedPriorityUser.profileId === userProfileId) {
            setSelectedPriorityUser({
                name: "",
                profileId: null,
            });
        } else {
            setSelectedPriorityUser({
                name,
                profileId: userProfileId,
            });
        }
    };

    // 두번째 칸 호출 api 메서드
    const { data: matchingPriorityPartners } = useQuery({
        queryKey: ["matchingPriorityPartners", userProfileId],
        queryFn: () => getMatchingUserPriority(Number(userProfileId)),
    });

    // 세번째 칸 호출 api 메서드
    const { data: matchedPriorityPartners } = useQuery({
        queryKey: ["matchedPriorityPartners", selectedPriorityUser],
        enabled: selectedPriorityUser.profileId !== null,
        queryFn: () => getMatchedUserPriority(selectedPriorityUser.profileId || 0),
    });

    const qc = useQueryClient();

    const { mutate: onMatching, isPending: isMatchingPending } = useMutation({
        mutationFn: (partnerId: number) =>
            postMatching({
                maleUserId:
                    matchUser?.gender === "남자" ? matchUser?.userId || 0 : partnerId,
                femaleUserId:
                    matchUser?.gender === "여자" ? matchUser?.userId || 0 : partnerId,
            }),
        onSuccess: () => {
            openNotificationWithIconByRecommend(matchUser?.name || "");
            qc.invalidateQueries({ queryKey: ["matchingPriorityPartners"] });
            qc.invalidateQueries({ queryKey: ["matchedPriorityPartners"] });
            qc.invalidateQueries({ queryKey: ["matchUser"] });
            qc.invalidateQueries({ queryKey: ["userMatchingPartners"] });
        },
        onError: () =>
            openNotificationWithIconByRecommendFail(matchUser?.name || ""),
    });

    const { mutate: onTmpMatching, isPending: isTmpMatchingPending } = useMutation({
        mutationFn: (partnerId: number) =>
            postTmpMatching({
                maleUserId:
                    matchUser?.gender === "남자" ? matchUser?.userId || 0 : partnerId,
                femaleUserId:
                    matchUser?.gender === "여자" ? matchUser?.userId || 0 : partnerId,
            }),
        onSuccess: () => {
            openNotificationWithIconByRecommend(matchUser?.name || "");
            qc.invalidateQueries({ queryKey: ["matchingPriorityPartners"] });
            qc.invalidateQueries({ queryKey: ["matchedPriorityPartners"] });
            qc.invalidateQueries({ queryKey: ["matchUser"] });
            qc.invalidateQueries({ queryKey: ["userMatchingPartners"] });
        },
        onError: () =>
            openNotificationWithIconByRecommendFail(matchUser?.name || ""),
    });

    const filterMatchingUserPriorityByChoiceRanking = (
        partners: GetMatchingUserPriority[]
    ) => {
        return partners.filter(
            (partner) => partner.choiceRanking !== "선택하지 않음"
        );
    };

    const filterMatchedUserPriorityByChoiceRanking = (
        partners: GetMatchedUserPriority[]
    ) => {
        return partners.filter(
            (partner) => partner.choiceRanking !== "선택하지 않음"
        );
    };

    return (
        <>
            <PrioritySection>
                <Typography.Title level={2}>
                    "{matchUser.name}"님이 선택한 우선 순위 :{" "}
                    {matchingPriorityPartners &&
                        filterMatchingUserPriorityByChoiceRanking(
                            matchingPriorityPartners
                        ).length}{" "}
                </Typography.Title>
                <MatchingPartnerSectionSecond ref={enableRecommendBoxRef}>
                    {(isMatchingPending || isTmpMatchingPending) ? (
                        <Loading />
                    ) : (
                        <>
                            {matchingPriorityPartners && (
                                <List
                                    pagination={{ pageSize: 10, onChange: onScrollTop }}
                                    bordered
                                    dataSource={filterMatchingUserPriorityByChoiceRanking(
                                        matchingPriorityPartners
                                    )}
                                    renderItem={(partner) => (
                                        <div
                                            style={{
                                                pointerEvents: partner.refundUser
                                                    ? "none"
                                                    : "unset",
                                            }}
                                        >
                                            <PriorityProfileCard
                                                hasMatching={
                                                    matchUser.hasMatching || false
                                                }
                                                partner={partner}
                                                onCardClick={() =>
                                                    onPriorityUserClick(
                                                        partner.name,
                                                        partner.userProfileId
                                                    )
                                                }
                                                onMatching={() => onMatching(partner.userId)}
                                                onTmpMatching={() => onTmpMatching(partner.userId)}
                                            />
                                        </div>
                                    )}
                                />
                            )}
                        </>
                    )}
                </MatchingPartnerSectionSecond>
            </PrioritySection>
            <PrioritySection>
                <Typography.Title level={2}>
                    {selectedPriorityUser.profileId ? (
                        <div>
                            "{selectedPriorityUser.name}"님이 선택한 우선 순위 :{" "}
                            {matchedPriorityPartners &&
                                filterMatchedUserPriorityByChoiceRanking(
                                    matchedPriorityPartners
                                ).length}{" "}
                        </div>
                    ) : (
                        "우측의 프로필을 클릭해 우선순위를 확인하세요"
                    )}
                </Typography.Title>
                <MatchingPartnerSectionThird ref={enableRecommendBoxRef}>
                    {matchedPriorityPartners && (
                        <List
                            pagination={{ pageSize: 10, onChange: onScrollTop }}
                            bordered
                            dataSource={matchedPriorityPartners}
                            renderItem={(partner) => {
                                if (partner.choiceRanking === "선택하지 않음") return;
                                return <PriorityProfileCardSecond partner={partner} />;
                            }}
                        />
                    )}
                </MatchingPartnerSectionThird>
            </PrioritySection>
        </>
    )
}
export default PriorityDetailSection;

const PrioritySection = styled.div`
  height: auto;
  width: 100%;
  border: 1px solid #d9d9d9;
  border-radius: 12px;
  padding: 12px;
`;

const MatchingPartnerSectionSecond = styled.div`
  max-height: 1000px;
  overflow-y: auto;
`;

const MatchingPartnerSectionThird = styled.div`
  max-height: 1000px;
  overflow-y: auto;
  height: 100%;
`;