import UserLayout from "./components/UserLayout";
import styled from "@emotion/styled";
import { Typography, notification } from "antd";
import { useQuery } from "@tanstack/react-query";
import { getCareerInspection } from "../../utils/api/user";
import CareerInspectionCard from "./components/CareerInspectionCard";
import Loading from "../../common/components/Loading";
import { useState } from "react";

const Career = () => {
  const [isLoading, setIsLoading] = useState(false);
  const onChangeLoading = (loading: boolean) => {
    setIsLoading(loading);
  };

  const { data } = useQuery({
    queryKey: ["career"],
    queryFn: () => getCareerInspection(),
  });

  const [api, contextHolder] = notification.useNotification();
  const openNotificationWithIcon = (name: string) => {
    api["success"]({
      message: "분류 완료!",
      description: `${name}님의 외모 분류가 완료되었어요`,
      duration: 3,
    });
  };

  if (isLoading)
    return (
      <Paper>
        <Loading />
      </Paper>
    );

  return (
    <UserLayout>
      {contextHolder}
      <Paper>
        <Typography.Title level={2}>유저 능력 분류</Typography.Title>
        {!!data && (
          <>
            {data.map((careerData) => (
              <CareerInspectionCard
                careerData={careerData}
                openNotificationWithIcon={openNotificationWithIcon}
                onChangeLoading={onChangeLoading}
              />
            ))}
          </>
        )}
      </Paper>
    </UserLayout>
  );
};

const Paper = styled.div`
  background-color: white;
  border-radius: 12px;
  padding: 16px;
`;

export default Career;
